import React from 'react'

import { Card } from 'primereact/card'
import { ProgressBar } from 'primereact/progressbar'

export const LoadingScreen = (props: any) => {

  return (
    <Card className='fixed z-5 top-0 bottom-0 left-0 right-0 opacity-70 flex justify-content-center align-items-center'>
      <div>
        <div className='font-bold text-xl'>{props.title}</div>
        <div>
          <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
        </div>
      </div>
    </Card>
  )
}
