import ReactEcharts from "echarts-for-react"

export default function PieChart(props: any) {

  const getOptions = () => ({
    color: props.color ?? ["#5470c6", "#ee6666", "#91cc75", "#fac858", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"],
    title: {
      text: props.title ?? '',
      x: "center" //  제목 정렬 위치 (left/center/right)
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    legend: { //  아래 목차
      // orient: "vertical",
      top: 'bottom',

      data: props.legendData ?? []
    },
    series: [
      {
        name: props.seriesName ?? '',
        type: "pie",
        radius: "55%",
        center: ["50%", "50%"], // 중앙 x,y 좌표1
        label: {
          alignTo: 'edge',
          formatter: '{name|{b}}\n{time|{c} EA}',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            time: {
              fontSize: 10,
              color: '#999'
            }
          }
        },
        data: props.mainData,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  })
  return (
    <div>
      <ReactEcharts option={getOptions()} style={{ height: 300 }} />
    </div>
  )
}
