import dayjs from 'dayjs'
import FileDownload from 'file-saver'
import { useRef, useState } from 'react'

import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { ContextMenu } from 'primereact/contextmenu'
import { DataTable, DataTableFilterMeta } from 'primereact/datatable'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'

import { useDayJS } from '../../../../hooks/useDayJS'
import { useExport } from '../../../../hooks/useExport'

import { ClipboardButton } from '../../../../components/button/Buttion.Clipboard'
import { CustomButton } from '../../../../components/button/Button'
import { CustomToast } from '../../../../components/toast/Toast'
import { defaultSelectedLicenseListTableColumn, licenseCategoryList, licenseListColumn, licenseTypeList } from '../../../../constants/table'
import { URLLIST } from '../../../../constants/url'
import { useAuth } from '../../../../hooks/useAuth'
import { fileDownloadAPI } from '../../../../services/api'

function LicenseTable(props: any) {

  const columns = licenseListColumn

  const cm = useRef<ContextMenu>(null)
  const toast = useRef<Toast>(null)


  const { getToday } = useDayJS()
  const { loginCheck } = useAuth()
  const { exportExcel } = useExport()

  const [dates, setDates] = useState<Date | null>(null)
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [selectedColumns, setSelectedColumns] = useState<TableColums[]>(columns.filter((el: TableColums) => defaultSelectedLicenseListTableColumn.includes(el.field)))
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [contextSelect, setContextSelect] = useState<ILicense | null>(null)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    License_Type: { value: null, matchMode: FilterMatchMode.IN },
    Category: { value: null, matchMode: FilterMatchMode.IN },
    License_Expiration_Date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  })


  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value
    let _filters = { ...filters }

    // @ts-ignore
    _filters['global'].value = value;

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      License_Type: { value: null, matchMode: FilterMatchMode.IN },
      Category: { value: null, matchMode: FilterMatchMode.IN },
      License_Expiration_Date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    })
    setGlobalFilterValue('')
    setSelectedColumns(columns.filter((el: TableColums) => defaultSelectedLicenseListTableColumn.includes(el.field)))
    setDates(null)
  }

  //  header
  const renderHeader = () => {
    return (
      <div className="flex flex-wrap justify-content-between">
        <div style={{ textAlign: 'left', flex: '1 0 auto' }}>
          <CustomButton
            // label='Export'
            icon='pi pi-file-excel'
            className='p-button-success'
            tooltip="Export to Excel"
            onClick={() => exportExcel({ data: filteredValue.length > 0 ? filteredValue : props.licenseList, fileName: `license_${getToday({}).replace(/[^0-9]+/g, '')}`, header: selectedColumns })}
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            // @ts-ignore <- 이거 안넣어주면 에러발생함
            disabled={filters.global.value && (filteredValue.length === 0)}
          />
        </div>
        <div className='flex flex-wrap flex-grow'>
          <div className='mr-3'>
            <CustomButton
              label="Filter Clear"
              styleOption="outlined"
              onClick={initFilter}
            />
          </div>
          <div className='flex flex-wrap'>
            <div className='mr-3' style={{ textAlign: 'left', flex: '1 1 auto' }}>
              <MultiSelect maxSelectedLabels={4} value={selectedColumns} options={licenseListColumn} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
            </div>
            <div className='flex' style={{ flex: '1 1 auto', flexDirection: 'column', flexWrap: 'wrap' }}>
              <span className="p-input-icon-left" style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-search" style={{ marginRight: '0.5em' }} />
                <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder="Keyword Search" />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //  table header MultiSelect 관련
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field))
    setSelectedColumns(orderedSelectedColumns)
  }

  //  default Date Body
  const dateBodyTemplate = (rowData: ILicense, classify: string) => {
    const short = (rowData[classify] as string).split(' ')[0] !== '-' ? (rowData[classify] as string).split(' ')[0] : 'unlimit'
    return (
      <div className='w-6rem'>{short}</div>
    )
  }

  //  License Key Column Body
  const licenseKeyBody = (rowData: ILicense) => {
    const key = rowData.LicenseKey
    return <div className='flex justify-content-between'>
      <div>{key}</div>
      <div className='ml-3 opacity-40'>
        <ClipboardButton textToBeCopied={key} />
      </div>
    </div>
  }

  //  Usage Column Body
  const licenseUsageBody = (rowData: ILicense) => {
    return <div className='flex justify-content-center'>{rowData.Usage} %</div>
  }

  //  License Expire Date Body Filter
  const expireDateFilter = (options: any) => {
    if (dates) options.filterModel.value = options.value = dates
    return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="yy-mm-dd" placeholder="yy-mm-dd" mask="9999-99-99" />
  }

  //  ILicense Expire Date Body Filter Footer
  const expireDateFilterFooter = () => {
    return <div>
      <Divider className='mt-0' />
      <div className="flex ml-3 align-items-center mb-3">
        <Checkbox inputId="unlimit" name="unlimit" value="unlimit" onChange={unlimitCheck} checked={dates instanceof Date && dates.getTime() === new Date("2999-12-31").getTime()} />
        <label htmlFor="unlimit" className="ml-2">unlimit only</label>
      </div>
    </div>
  }

  const unlimitCheck = (e: any) => {
    if (!e.checked) setDates(new Date())
    else setDates(new Date('2999-12-31'))
  }

  //  License Issue Type Body
  const licenseIssueTypeBody = (rowData: ILicense) => {
    return <div className={classNames('flex justify-content-center')}>{rowData.License_Issue_Type === 1 ? 'new' : 'reissuance'}</div>
  }

  //  License Expire Date Body
  const expireDateBody = (rowData: ILicense) => {
    const date = dayjs(rowData.License_Expiration_Date).format('YYYY-MM-DD')
    const isExpire = dayjs(date).isBefore(getToday({}))
    return <div className={classNames('flex justify-content-center', { 'text-red-500': isExpire, })}>{rowData.License_Limit === 2 ? date : 'N/A'}</div>
  }

  //  License Type
  const licenseTypeBody = (rowData: ILicense) => { return <div className='w-8rem'><span>{rowData.License_Type}</span></div> }
  const licenseTypeFilterBody = (options: any) => { return <span >{options}</span> }
  const licenseTypeFilter = (options: ColumnFilterElementTemplateOptions) => {
    return <MultiSelect
      value={options.value}
      options={licenseTypeList}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={licenseTypeFilterBody}
      placeholder="Select a Status" className="p-column-filter"
    />
  }

  //  License Category
  const licenseCategoryBody = (rowData: ILicense) => { return <div className='flex justify-content-center'><span>{rowData.Category}</span></div> }
  const licenseCategoryFilterBody = (options: any) => { return <span >{options}</span> }
  const licenseCategoryFilter = (options: ColumnFilterElementTemplateOptions) => {
    return <MultiSelect
      value={options.value}
      options={licenseCategoryList}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={licenseCategoryFilterBody}
      placeholder="Select a Category" className="p-column-filter"
    />
  }

  const handleContextMenu = (e: any) => {
    cm.current!.show(e.originalEvent)
  };

  const contextMenuModel = [
    {
      label: 'PDF Download', icon: 'pi pi-fw pi-file-pdf',
      command: () => downloadPDF(contextSelect)
    },
    {
      label: 'Delete', icon: 'pi pi-fw pi-times',
      command: () => deleteLicense(contextSelect)
    }
  ]

  const downloadPDF = async (data: any) => {
    try {
      const result = await fileDownloadAPI({ path: URLLIST['LICENSE_PDF_DOWNLOAD'], body: { path: data?.File_Path ?? '' } })
      const blobResult = (new Blob([result as unknown as BlobPart], { type: 'application/pdf' }))
      FileDownload(blobResult, [...data.File_Path.split('/')].pop())
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'Failed to download PDF File', det: error.message })
    }
  }

  const deleteLicense = async (data: any) => {
    props.setLoadingScreenTitle('License now deleting...')
    props.setIsLoading(true)
    await loginCheck()
      .then((result) => {
        props.deleteLicense({ id: data.ID })
          .then((r: any) => {
            if (r?.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: r.message, det: `Name : ${data.License_Name}`, time: 5000 })
              if (props.selectedLicense && data.ID === props.selectedLicense?.ID) {
                props.setSelectedLicense(null)
              }
              props.getLicenseList()
            }
            else CustomToast({ toast, sev: 'error', sum: 'license delete fail', det: `Name : ${data.License_Name}`, time: 5000 })
          })
          .catch((e: any) => {
            console.log(e)
            CustomToast({ toast, sev: 'error', sum: 'license delete fail', det: `Name : ${data.License_Name}`, time: 5000 })
          })
      })
    props.setIsLoading(false)
  }

  // const editInfo = (rowData: ILicense) => {
  //   setSelectedRowData(rowData)
  //   setShowEditModal(true)
  // }

  // const optionBody = (rowData: ILicense) => {
  //   return (
  //     <React.Fragment>
  //       <CustomButton
  //         icon="pi pi-cog"
  //         className='pl-0 pr-0'
  //         styleOption={'outlined'}
  //         onClick={() => editInfo(rowData)}
  //       />
  //     </React.Fragment>
  //   )
  // }

  return (
    <div className="card">
      {/* <LicenseInfoEditModal type='none' ref={childRef}
        editLicense={props.editLicense}
        fetchDataLicenseList={props.fetchDataLicenseList}
      /> */}
      <Toast ref={toast} content={null} />
      <ContextMenu model={contextMenuModel} ref={cm} onHide={() => setContextSelect(null)} />
      <DataTable
        //  테이블 표기 데이터 | 테이블 헤더 | 테이블 스타일 추가
        value={props.licenseList} header={renderHeader} tableStyle={{ minWidth: '50rem' }}
        //  글로벌 필터
        globalFilterFields={selectedColumns.map((el: TableColums) => el.field)}
        //  테이블 크기 | 테이블 줄무늬 추가 | 컬럼 정렬 추가
        size='small' stripedRows removableSort
        //  검색 필터 옵션 | 데이터 없을시 보여주는 메시지 | 컬럼 헤더 위치 이동 가능
        filters={filters} emptyMessage="No License found." reorderableColumns
        //  페이지 네이션 추가
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
        //  context menu 추가
        onContextMenu={(e) => handleContextMenu(e)} contextMenuSelection={contextSelect || undefined} onContextMenuSelectionChange={(e) => setContextSelect(e.value)}
        //  table 컬럼 선택기능 추가 ( 단일 선택 )
        selectionMode="single" selectionPageOnly selection={props.selectedLicense} onSelectionChange={(e) => props.setSelectedLicense(e.value)}
        //  필터 데이터 변경시 동작
        onValueChange={filteredValues => setFilteredValue(filteredValues)}
      >
        {selectedColumns.map((col) => {
          if (col.field === 'License_Start_Date') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={(e) => dateBodyTemplate(e, col.field)} sortable />
          }
          else if (col.field === 'License_Issued_Date') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={(e) => dateBodyTemplate(e, col.field)} sortable />
          }
          else if (col.field === 'License_Issue_Type') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={licenseIssueTypeBody} sortable />
          }
          else if (col.field === 'License_Expiration_Date') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} headerClassName='w-6rem' body={expireDateBody}
              dataType="date"
              filter filterElement={expireDateFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterOperator={false} filterFooter={expireDateFilterFooter}
              sortable />
          }
          else if (col.field === 'LicenseKey') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={(e) => licenseKeyBody(e)} sortable />
          }
          else if (col.field === 'Usage') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={(e) => licenseUsageBody(e)} sortable />
          }
          else if (col.field === 'Category') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} headerClassName='w-6rem' body={licenseCategoryBody}
              filter filterElement={licenseCategoryFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
              sortable />
          }
          else if (col.field === 'License_Type') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} headerClassName='w-11rem' body={licenseTypeBody}
              filter filterElement={licenseTypeFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
              sortable />
          }
          else return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} sortable />
        })}
        {/* <Column field="Edit" header="" exportable={false} body={optionBody} /> */}
      </DataTable>
      {/* <LicenseEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        data={selectedRowData} /> */}
    </div>
  )
}

export default LicenseTable