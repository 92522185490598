import activationPageCentence from './activate/sentence.json'
import commonCentence from './common.json'
import modalLicenseIssue from './modal/licenseIssue/sentence.json'
import promotionPageCentence from './promotion/sentence.json'
import userLicenseDashBoardCentence from './user/license/dashboard/sentence.json'
import userLicenseListCentence from './user/license/list/sentence.json'

export default {
  ...promotionPageCentence,
  ...activationPageCentence,
  ...userLicenseDashBoardCentence,
  ...userLicenseListCentence,
  ...modalLicenseIssue,
  ...commonCentence,
}