import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from "react"
import './Request.css'

import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from '../../../../hooks/useDayJS'
import { useLicense } from "../../../../hooks/useLicense"

import { CustomButton } from '../../../../components/button/Button'
import { RequestLicenseModal } from '../../../../components/modal/Modal.LicenseIssue'
import { LoadingScreen } from '../../../../components/modal/Modal.LoadingScreen'
import { CustomToast } from '../../../../components/toast/Toast'
import LicenseIssueRequestTable from './Table'

function UserLicenseRequestPage(props: any) {
  const toast = useRef<Toast>(null)

  const { loginCheck } = useAuth()
  const { getToday } = useDayJS()
  const {
    licenseIssueRequestList,
    getLicenseIssueRequestList,
    setLicenseIssueRequestList,
    deleteLicenseRequest
  } = useLicense()

  const [isLoading, setIsLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('License Request List now Loading...')
  const [selectedLicenseRequest, setSelectedLicenseRequest] = useState<any[]>([])

  //  데이터 가져오기
  const fetchData = async () => {
    setIsLoading(true)
    try {
      await loginCheck()
        .then((result) => {
          getLicenseIssueRequestList()
            .catch((error) => { CustomToast({ toast, sev: 'error', sum: 'Promotion License Issue Request List Request Fail', det: error?.message ?? 'error', time: 10000 }) })
        })
    } catch (error: any) {
    } finally {
      setIsLoading(false)
    }
  }

  //  삭제 요청
  const deleteRequest = async () => {
    setIsLoading(true)
    setLoadingMessage('License Issue Request now Deleting...')
    try {
      await loginCheck()
        .then(async (result) => {
          let _selectedLicenseRequest = selectedLicenseRequest
          const deletePromises = _selectedLicenseRequest.map((el: any) => { return deleteLicenseRequest({ id: el.ID }) })
          const results = await Promise.all(deletePromises)

          let successfulResults: { [key: string]: any }[] = []
          let failedResults: { [key: string]: any }[] = []


          results.forEach((result: any, index: number) => {
            const el: any = _selectedLicenseRequest[index]

            if (result?.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: `delete license issue Request ${el.License_Name} success`, det: `delete success` })
              successfulResults.push(el)
            } else {
              CustomToast({ toast, sev: 'error', sum: `delete license issue Request ${el.License_Name} fail`, det: result?.message })
              failedResults.push(el)
            }
          })

          _selectedLicenseRequest = successfulResults

          const remainingLicenseIssueRequestList = licenseIssueRequestList.filter((itemA: any) => {
            return !_selectedLicenseRequest.some((itemB: any) => {
              return itemB.ID === itemA.ID
            })
          })

          setLicenseIssueRequestList(remainingLicenseIssueRequestList)
          setSelectedLicenseRequest(failedResults)
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'license issue request delete fail', det: error.message, time: 5000 })
    } finally {
      setIsLoading(false)
      setLoadingMessage('License Request List now Loading...')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {isLoading &&
        <LoadingScreen title={loadingMessage} />
      }
      <Card className='w-full'>
        <Toast ref={toast} content={null} />
        <Card className='h-4rem'>
          <div className='flex justify-content-between flex-wrap'>
            <div className='font-bold'>License Request</div>
            <div className='flex font-bold'>
              <div>{props.t('page_last_update_time')} : {getToday({})}</div>
            </div>
          </div>
        </Card>
        <Divider />
        <div className='flex justify-content-between align-content-center'>
          <div className='ml-3'>
            <p className='font-bold'>
              total : {licenseIssueRequestList.length}
              {/* <span className='text-blue-500'> available</span> : {currentlyAvailableLicenseNow.length}
              <span className='text-red-500'> expired</span> : {currentlyExpiredLicenseNow.length} */}
            </p>
          </div>
          <div className='ml-3'>
            <RequestLicenseModal
              t={props.t}
              i18n={props.i18n}
              userType={props.userType}
              getLicenseIssueRequestList={getLicenseIssueRequestList}
            />
            <CustomButton
              label='delete'
              severity='danger'
              className='ml-2'
              onClick={deleteRequest}
              disabled={!selectedLicenseRequest.length}
            />
            <CustomButton
              label='Refresh'
              className='ml-2'
              onClick={fetchData}
            />
          </div>
        </div>
        <Divider />
        <LicenseIssueRequestTable
          userType={props.userType}
          licenseIssueRequestList={licenseIssueRequestList}
          selectedLicenseRequest={selectedLicenseRequest}
          setSelectedLicenseRequest={setSelectedLicenseRequest} />
        <Divider />
      </Card>
    </>
  )
}

export default UserLicenseRequestPage
