import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { Toast } from "primereact/toast"
import { useEffect, useRef, useState } from "react"
import { LoadingScreen } from "../../../../components/modal/Modal.LoadingScreen"
import { CustomToast } from "../../../../components/toast/Toast"
import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from "../../../../hooks/useDayJS"
import { useLicense } from "../../../../hooks/useLicense"
import LicenseIssueHistoryTable from "./Table"

function AdminLicenseIssueHistoryPage(props: any) {
  const toast = useRef<Toast>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [selectedIssueHistory, setselectedIssueHistory] = useState([])

  const { loginCheck } = useAuth()
  const { getToday } = useDayJS()
  const {
    licenseIssueHistory,
    allowdLicenseList,
    deniedLicense,
    getLicenseIssueHistory
  } = useLicense()

  const fetchData = async () => {
    setIsLoading(true)
    try {
      await loginCheck()
        .then((result) => {
          getLicenseIssueHistory()
            .catch((error) => { CustomToast({ toast, sev: 'error', sum: 'License Issue History Request Fail', det: error?.message ?? 'error', time: 10000 }) })
        })
    } catch (error: any) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {isLoading &&
        <LoadingScreen
          title="License Issue History now Loading..."
        />
      }
      <Card className='w-full'>
        <Toast ref={toast} content={null} />
        <Card className='h-4rem'>
          <div className='flex justify-content-between flex-wrap'>
            <div className='font-bold'>License Issue History</div>
            <div className='flex font-bold'>
              <div>{props.t('page_last_update_time')} : {getToday({})}</div>
            </div>
          </div>
        </Card>
        <Divider />
        <div className='flex justify-content-between align-content-center'>
          <div className='ml-3'>
            <p className='font-bold'>
              total : {licenseIssueHistory.length}
              <span className='text-blue-500'> Allow</span> : {allowdLicenseList.length}
              <span className='text-red-500'> Deny</span> : {deniedLicense.length}
            </p>
          </div>
          <div className='ml-3'>

          </div>
        </div>
        <Divider />
        <LicenseIssueHistoryTable
          licenseIssueHistory={licenseIssueHistory}
          selectedIssueHistory={selectedIssueHistory}
          setselectedIssueHistory={setselectedIssueHistory}
        />
      </Card>
    </>
  )
}

export default AdminLicenseIssueHistoryPage