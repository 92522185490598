import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import { Accordion, AccordionTab } from 'primereact/accordion'
import { Divider } from 'primereact/divider'
import { InputTextarea } from 'primereact/inputtextarea'
import { Knob } from 'primereact/knob'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'

import { CustomButton } from '../../../../components/button/Button'
import { CustomToast } from '../../../../components/toast/Toast'

import { useAuth } from '../../../../hooks/useAuth'
import { useDayJS } from '../../../../hooks/useDayJS'
import { useLicense } from '../../../../hooks/useLicense'

export const LicenseDetailAccordion = (props: {
  selectedLicense: ILicense
}) => {
  const toast = useRef<Toast>(null)
  const { getToday } = useDayJS()
  const { loginCheck } = useAuth()
  const { editLicense } = useLicense()
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState<string>('')
  const [isWorking, setIsWorking] = useState(false)

  useEffect(() => {
    setValue(props.selectedLicense.Comment || 'No comments registered.')
  }, [props.selectedLicense])


  const onClickEdit = async () => {
    setIsWorking(true)
    if (isEdit) {
      await loginCheck()
        .then((result) => {
          props.selectedLicense.Comment = value
          editLicense({ data: props.selectedLicense })
            .then((r: any) => {
              if (r?.state === 'success') {
                CustomToast({ toast, sev: 'success', sum: r.message, det: `Name : ${props.selectedLicense.License_Name}'s comment update success.`, time: 5000 })
                setIsEdit((prve) => !prve)
              }
              else CustomToast({ toast, sev: 'error', sum: 'comment update fail', det: `Name : ${props.selectedLicense.License_Name}'s comment update fail.`, time: 5000 })
            })
            .catch((e) => {
              console.log(e)
              CustomToast({ toast, sev: 'error', sum: 'comment update fail', det: `Name : ${props.selectedLicense.License_Name}`, time: 5000 })
            })
            .then(() => setIsWorking(false))
        })
    }
    else {
      setIsEdit((prve) => !prve)
      setIsWorking(false)
    }
  }

  return (
    <>
      <Toast ref={toast} content={null} />
      <Accordion activeIndex={0} className='mt-3'>
        <AccordionTab header={
          <span className='flex justify-contents-left'>License [ {props.selectedLicense.License_Name} ]'s Detail
            {dayjs(props.selectedLicense.License_Expiration_Date).isBefore(getToday({})) && <span className={classNames({ 'ml-2 font-bold text-red-500': dayjs(props.selectedLicense.License_Expiration_Date).isBefore(getToday({})) })}>(Expired!)</span>}
          </span>}>
          <div className='grid'>
            <div className='col-12 md:col-12 lg:col-2' style={{ textAlign: 'left' }}>
              <Divider />
              <div className='my-2'>
                <span className='font-bold mr-4 w-10rem'>[ License Usage ]</span>
              </div>
              <Divider />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                  <Knob
                    value={parseFloat(((props.selectedLicense.Used_Quantity / props.selectedLicense.Total_Quantity) * 100).toFixed(2))}
                    valueTemplate={"{value}%"} min={0} max={100.00}
                  />
                </div>
                <div>
                  <span className='font-bold'>
                    Used : {props.selectedLicense.Used_Quantity} / Total : {props.selectedLicense.Total_Quantity}
                  </span>
                </div>
              </div>
            </div>

            <div className='col-12 md:col-12 lg:col-10'>
              <Divider />
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold mr-4 w-10rem text-left'>[ License Info ] </span>
              </div>
              <Divider />
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Key</span>
                <span style={{ whiteSpace: 'nowrap' }}>{props.selectedLicense.LicenseKey}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Type / Limit</span>
                <span>{props.selectedLicense.License_Type} / {props.selectedLicense.License_Limit === 2 ? 'limit' : 'unlimit'}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Issue</span>
                <span>{props.selectedLicense.License_Issued_Date}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Start</span>
                <span>{props.selectedLicense.License_Start_Date}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Expire</span>
                <span className={classNames({ 'font-bold text-red-500': dayjs(props.selectedLicense.License_Expiration_Date).isBefore(getToday({})) })}>{props.selectedLicense.License_Expiration_Date}</span>
              </div>
            </div>

            <div className='col-12 md:col-6 lg:col-6'>
              <Divider />
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold mr-4 w-10rem text-left'>[ Manager Info ]</span>
              </div>
              <Divider />
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Login ID</span>
                <span>{props.selectedLicense.Issue_Mail}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>IP</span>
                <span>{props.selectedLicense.Manager_IP}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>UUID</span>
                <span style={{ whiteSpace: 'nowrap' }}>{props.selectedLicense.UID}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Before UUID</span>
                <span style={{ whiteSpace: 'nowrap' }}>{props.selectedLicense.RUID ?? '-'}</span>
              </div>
            </div>

            <div className='col-12 md:col-6 lg:col-6'>
              <Divider />
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold mr-4 w-10rem text-left'>[ Etc Info ]</span>
              </div>
              <Divider />
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Name</span>
                <span>{props.selectedLicense.License_Name}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Project</span>
                <span>{props.selectedLicense.Project}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Customer</span>
                <span>{props.selectedLicense.Customer}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>Mail</span>
                <span>{props.selectedLicense.User_Mail ?? '-'}</span>
              </div>
              <div className='my-2 flex justify-contents-left'>
                <span className='font-bold w-8rem text-left'>User / Country</span>
                <span>{props.selectedLicense.User_Name ?? '-'} / {props.selectedLicense.Country ?? '-'}</span>
              </div>
            </div>

            <div className='col-12'>
              <Divider />
              <div className='my-2 flex justify-content-between'>
                <div className='flex align-content-center'>
                  <div className='flex align-items-center'>
                    <span className='font-bold mr-4 w-10rem text-left'>[ comment ]</span></div></div>
                <div className='flex'>
                  <div>
                    <CustomButton
                      icon={`pi ${isEdit ? 'pi-save' : 'pi-pencil'}`}
                      className='pl-0 pr-0'
                      onClick={() => onClickEdit()}
                      disabled={isWorking}
                    /></div>
                </div>
              </div>
              <Divider />
              <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} rows={5} className="w-full" disabled={!isEdit} />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </>
  )
}

export default LicenseDetailAccordion