import "../styles/LogIn.css"
import { useRef } from "react"
import { Controller, useForm, FieldError } from "react-hook-form"

import { Toast } from "primereact/toast"
import { Button } from "primereact/button"
import { Password } from "primereact/password"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"

import { useAuth } from "../hooks/useAuth"
import { CustomToast } from "../components/toast/Toast"

import LogoImage2 from '../assets/ZconLogo2.png'

function Login() {
  const toast = useRef<Toast>(null)

  const { login } = useAuth()

  const defaultValues = { mail: "", password: "" }

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues })

  const onSubmit = async (loginData: any) => {
    try {
      try {
        login({ loginData })
          .then((response: any) => { })
          .catch((error) => {
            CustomToast({ toast, sev: 'error', sum: 'login fail.', det: error.response?.data?.message, time: 10000 })
          })
      } catch (error) { console.error(error) }
    } catch (error) { console.error(error) }
  }

  const getFormErrorMessage = (name: string) => {
    const errorMessages = errors as { [key: string]: FieldError }
    return errorMessages[name] ? <small className="p-error">{errorMessages[name].message}</small> : <small className="p-error"></small>
  }

  return (
    <div className="Login">
      <Toast ref={toast} content={null} />
      <div className="inputBox">
        <div className="card">
          <img
            className="inputHeaderImage"
            alt="ZConverter Cloud"
            src={LogoImage2}
          />
          <div style={{ color: "#1470a9", marginTop: "-5px" }}>
            <h3>ZConverter License Portal</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex-grow-1 gap-2">
            <div className="mb-4">
              <Controller
                name="mail"
                control={control}
                rules={{ required: "Email is required." }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ "p-error": errors[field.name] })}></label>
                    <span className="p-float-label">
                      <InputText
                        id={field.name}
                        value={field.value}
                        className={classNames("w-full", {
                          "p-invalid": fieldState.error,
                        })}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      <label htmlFor={field.name}>email</label>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="password"
                control={control}
                rules={{ required: "Password is required." }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className={classNames({ "p-error": errors[field.name] })}></label>
                    <span className="p-float-label">
                      <Password
                        id={field.name}
                        {...field}
                        className={classNames("w-full", {
                          "p-invalid": fieldState.error,
                        })}
                        feedback={false}
                        onChange={(e) => field.onChange(e.target.value)}
                        toggleMask
                      />
                      <label htmlFor={field.name}>password</label>
                    </span>
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <Button className="w-full" label="Submit" type="submit" icon="pi pi-check" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
