import { Button } from 'primereact/button'

export const CustomButton = (props: any) => {

  const emptyOnClick = () => { }

  return (
    <Button
      label={props?.label ?? null}
      icon={props?.icon ?? null}
      loading={props?.loading ?? false}
      iconPos={props?.iconPos ?? 'left'}
      className={props?.className}
      severity={props?.severity ?? null}
      tooltip={props?.tooltip}
      tooltipOptions={props?.tooltipOptions}
      onClick={props?.onClick || emptyOnClick}
      disabled={props?.disabled}
      size={props?.size}
      type={props?.type}
      style={props?.style}
      outlined={props?.styleOption?.includes('outlined')}
      rounded={props?.styleOption?.includes('rounded')}
      text={props?.styleOption?.includes('text')}
      raised={props?.styleOption?.includes('raised')}
    />
  )
}