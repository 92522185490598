import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { URLLIST } from '../constants/url'
import { deleteAPI, getAPI, postAPI, putAPI } from '../services/api'
import { useDayJS } from './useDayJS'


export const useLicense = () => {

  const { isToday, isNow } = useDayJS()
  const defaultLicenseCategorizationByLicenseType: { [key: string]: number } = {
    'ZCM (Migration)': 0,
    'ZDM (Backup)': 0,
    'ZDM (DR)': 0,
    'ZDM (Migration)': 0,
    'ZEM (서버)': 0,
    'ZEM (용량)': 0,
  }
  //  대쉬보드 라이센스 기한
  const [licenseDashBoardDatePeriod, setLicenseDashBoardDatePeriod] = useState<any>(null)
  //  전체 라이센스 목록
  const [licenseList, setlicenseList] = useState<any>([])
  //  오늘 만료되는 라이센스 목록
  const [licenseExpiringToday, setLicenseExpiringToday] = useState([])
  //  오늘 승인된 라이센스 목록
  const [licenseApprovedToday, setlicenseApprovedToday] = useState([])
  //  현재 사용가능한 라이센스 목록
  const [currentlyAvailableLicenseNow, setCurrentlyAvailableLicenseNow] = useState([])
  //  현재 만료된 라이센스 목록
  const [currentlyExpiredLicenseNow, setCurrentlyExpiredLicenseNow] = useState([])
  //  라이센스 발급 요청 목록
  const [licenseIssueRequestList, setLicenseIssueRequestList] = useState([])
  //  오늘 요청된 라이센스 목록
  const [licenseIssueRequestToday, setLicenseIssueRequestToday] = useState([])
  //  라이센스 발급 이력
  const [licenseIssueHistory, setLicenseIssueHistory] = useState([])
  //  승인된 라이센스 목록
  const [allowdLicenseList, setAllowdLicenseList] = useState([])
  //  거절된 라이센스 목록
  const [deniedLicense, setDeniedLicense] = useState([])
  //  라이센스 히스토리
  const [licenseHistory, setLicenseHistory] = useState([])
  //  유형별 라이센스 수량 (객체)
  const [licenseCategorizationByLicenseType, setLicenseCategorizationByLicenseType] = useState<{ [key: string]: number }>({
    'ZCM (Migration)': 0,
    'ZDM (Backup)': 0,
    'ZDM (DR)': 0,
    'ZDM (Migration)': 0,
    'ZEM (서버)': 0,
    'ZEM (용량)': 0,
  })
  //  타입별 라이센스 수량 (리스트)
  const [licenseCategorizationByLicenseTypeList, setLicenseCategorizationByLicenseTypeList] = useState([
    { name: 'ZCM', value: 0 },
    { name: 'ZDM', value: 0 },
    { name: 'ZEM', value: 0 },
  ])
  //  유형별 라이센스 수량 (리스트)
  const [licenseCategorizationList, setLicenseCategorizationList] = useState([
    { name: 'ZCM (Migration)', value: 0 },
    { name: 'ZDM (Backup)', value: 0 },
    { name: 'ZDM (DR)', value: 0 },
    { name: 'ZDM (Migration)', value: 0 },
    { name: 'ZEM (서버)', value: 0 },
    { name: 'ZEM (용량)', value: 0 },
  ])

  //  라이센스 리스트 갱신시 동작하는 필터들
  useEffect(() => {
    //  오늘 승인된 라이센스 필터
    const getLicenseApprovedToday = () => {
      setlicenseApprovedToday(licenseList.filter((el: ILicense) => isToday({ date: el.License_Issued_Date })))
    }
    //  오늘 만료되는 라이센스 필터
    const getLicenseExpiringToday = () => {
      setLicenseExpiringToday(licenseList.filter((el: ILicense) => isToday({ date: el.License_Expiration_Date })))
    }
    //  현재 이용가능한 라이센스 필터
    const getCurrentlyAvailableLicenseNow = () => {
      setCurrentlyAvailableLicenseNow(licenseList.filter((el: ILicense) => isNow({ date: el.License_Expiration_Date })))
    }
    //  현재 만료된 라이센스 필터
    const getCurrentlyExpiredLicenseNow = () => {
      setCurrentlyExpiredLicenseNow(licenseList.filter((el: ILicense) => !isNow({ date: el.License_Expiration_Date })))
    }

    getLicenseApprovedToday()
    getLicenseExpiringToday()
    getCurrentlyAvailableLicenseNow()
    getCurrentlyExpiredLicenseNow()
    categorizationByLicenseType({ list: licenseList })
    return () => { }
  }, [licenseList])

  //  라이센스 타입별 분리
  useEffect(() => {
    const categorizationLicense = () => {
      const _t: any[] = []
      Object.keys(licenseCategorizationByLicenseType).map((key) => {
        _t.push({ value: licenseCategorizationByLicenseType[key], name: key })
      })
      setLicenseCategorizationList(_t)
    }

    const categorizationLicenseByType = () => {
      const _t: { [key: string]: number } = { 'ZCM': 0, 'ZDM': 0, 'ZEM': 0 }
      Object.keys(licenseCategorizationByLicenseType).map((key) => {
        if (key.includes('ZCM')) { _t['ZCM'] += licenseCategorizationByLicenseType[key] }
        if (key.includes('ZDM')) { _t['ZDM'] += licenseCategorizationByLicenseType[key] }
        if (key.includes('ZEM')) { _t['ZEM'] += licenseCategorizationByLicenseType[key] }
      })

      const _t2: any[] = []
      Object.keys(_t).map((key) => {
        _t2.push({ value: _t[key], name: key })
      })
      setLicenseCategorizationByLicenseTypeList(_t2)
    }

    categorizationLicense()
    categorizationLicenseByType()
  }, [licenseCategorizationByLicenseType])


  //  라이센스 발급 이력 갱신시 동작 필터
  useEffect(() => {
    //  승인된 라이센스 목록 필터
    const getAllowdLicenseList = () => {
      setAllowdLicenseList(licenseIssueHistory.filter((el: ILicenseIssueHistory) => el.Issue_State === 'true'))
    }
    //  거절된 라이센스 목록 필터
    const getDeniedLicense = () => {
      setDeniedLicense(licenseIssueHistory.filter((el: ILicenseIssueHistory) => el.Issue_State === 'false'))
    }

    getAllowdLicenseList()
    getDeniedLicense()
    return () => { }
  }, [licenseIssueHistory])

  //  라이센스 대쉬보드 날짜 선택시 필터
  useEffect(() => {
    if (licenseDashBoardDatePeriod) {
      const startDate = dayjs(licenseDashBoardDatePeriod[0])
      const endDate = dayjs(licenseDashBoardDatePeriod[1])

      //  라이센스 리스트 필터링
      const filterLicenseList = () => {
        const _licenseList = licenseList.filter((el: ILicense) => dayjs(el.License_Issued_Date).isAfter(startDate) && dayjs(el.License_Issued_Date).isBefore(endDate) || dayjs(el.License_Issued_Date).isSame(startDate) || dayjs(el.License_Issued_Date).isSame(endDate))
        categorizationByLicenseType({ list: _licenseList })
      }

      filterLicenseList()
    }
    return () => { }
  }, [licenseDashBoardDatePeriod])

  //  라이센스 타입별 필터
  async function categorizationByLicenseType({ list }: { list: ILicense[] }) {
    const _t = { ...defaultLicenseCategorizationByLicenseType }
    list.map((el: ILicense) => { _t[el.License_Type] += 1 })
    setLicenseCategorizationByLicenseType(_t)
  }

  //  현재 발급된 라이센스 목록 
  async function getLicenseList() {
    try {
      const path = URLLIST['LICENSE_LIST']
      await getAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { setlicenseList(result.data.list) }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 삭제
  async function deleteLicense({ id }: { id: number }) {
    try {
      const path = URLLIST['LICENSE_DEIETE'].replace('{ID}', id.toString())
      return await deleteAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 정보 수정
  async function editLicense({ data }: { data: any }) {
    try {
      const path = URLLIST['LICENSE_EDIT'].replace('{ID}', data.ID.toString())
      return await putAPI({ path, body: data })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  발급 신청한 라이센스 목록
  async function getLicenseIssueRequestList() {
    try {
      const path = URLLIST['LICENSE_ISSUE_REQUEST_LIST']
      await getAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') {
            setLicenseIssueRequestList(result.data.list)
          }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 발급 이력
  async function getLicenseIssueHistory() {
    try {
      const path = URLLIST['LICENSE_ISSUE_HISTORY']
      await getAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') {
            setLicenseIssueHistory(result.data.list)
          }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 history
  async function getLicenseHistory({ type, id }: { type: string, id: number }) {
    try {
      const path = URLLIST['LICENSE_HISTORY'].replace('{TYPE}', type).replace('{ID}', id.toString())
      return await getAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { setLicenseHistory(result.data.list) }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 발급 요청
  async function requestLicense({ data }: { data: ILicenseRequsetForm }) {
    try {
      const path = URLLIST['LICENSE_ISSUE_REQUEST']
      return await postAPI({ path, body: data })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 발급 요청 삭제
  async function deleteLicenseRequest({ id }: { id: number }) {
    try {
      const path = URLLIST['LICENSE_ISSUE_REQUSET_DELETE'].replace('{ID}', id.toString())
      return await deleteAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  라이센스 발급 요청 승인/거절
  async function decideLicenseRequest({ id, decide, comment = '' }: { id: number, decide: boolean, comment: string }) {
    try {
      const path = URLLIST['LICENSE_ISSUE_DECIDE']
      return await postAPI({ path, body: { id, decide, comment } })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  return {
    //  변수
    licenseList,
    licenseHistory,
    licenseApprovedToday,
    licenseExpiringToday,
    currentlyAvailableLicenseNow,
    currentlyExpiredLicenseNow,
    licenseIssueRequestList,
    licenseIssueHistory,
    allowdLicenseList,
    deniedLicense,
    licenseCategorizationByLicenseType,
    licenseCategorizationList,
    licenseCategorizationByLicenseTypeList,
    //  함수
    getLicenseList,
    getLicenseIssueRequestList,
    getLicenseIssueHistory,
    getLicenseHistory,
    requestLicense,
    deleteLicenseRequest,
    deleteLicense,
    setLicenseIssueRequestList,
    decideLicenseRequest,
    editLicense,
    setLicenseDashBoardDatePeriod,
  }
}