//  라이센스 - 라이센스 타입 목록
export const licenseTypeList = ['ZCM (Migration)', 'ZDM (Backup)', 'ZDM (DR)', 'ZDM (Migration)', 'ZEM (서버)', 'ZEM (용량)']
//  라이센스 - 라이센스 타입 목록2
export const licenseTypeObject: { [key: string | number]: string } = {
  1: 'ZCM (Migration)',
  2: 'ZDM (Backup)',
  3: 'ZDM (DR)',
  4: 'ZDM (Migration)',
  5: 'ZEM (서버)',
  6: 'ZEM (용량)'
}
//  라이센스 - 라이센스 카테고리 목록
export const licenseCategoryList = ['test', 'poc', 'product']
//  프로모션 라이센스 - 프로모션 코드 상태 목록
export const promotionCodeTypeList = ['available', 'not available']

//  [ License Table ]
//  라이센스 페이지 - 라이센스 테이블 기본선택 컬럼
export const defaultSelectedLicenseListTableColumn = [
  'Category', 'Usage', 'License_Type', 'License_Name', 'LicenseKey', 'Total_Quantity', 'License_Expiration_Date', 'Last_Update_Date',
]
//  라이센스 페이지 - 라이센스 테이블 컬럼
export const licenseListColumn = [
  { field: 'Category', header: 'Category' },
  { field: 'Usage', header: 'Usage' },
  { field: 'License_Name', header: 'Name' },
  { field: 'License_Type', header: 'License Type' },
  { field: 'LicenseKey', header: 'Key' },
  { field: 'Project', header: 'Project' },
  { field: 'License_Issued_Date', header: 'Issue' },
  { field: 'License_Issue_Type', header: 'Issue Type' },
  { field: 'License_Expiration_Date', header: 'Expiration' },
  { field: 'Used_Quantity', header: 'Used' },
  { field: 'RUID', header: 'Before UUID' },
  { field: 'Comment', header: 'Comment' },
  { field: 'Country', header: 'Country' },
  { field: 'Customer', header: 'Customer' },
  { field: 'User_Mail', header: 'ZIA Mail' },
  { field: 'Issue_Mail', header: 'ID' },
  { field: 'Manager_IP', header: 'IP' },
  { field: 'UID', header: 'UUID' },
  { field: 'License_Start_Date', header: 'Start' },
  { field: 'User_Name', header: 'User' },
  { field: 'License_Version', header: 'Version' },
  { field: 'Last_Update_Date', header: 'Update' },
  { field: 'Total_Quantity', header: 'Quantity' },
]

//  라이센스 요청 페이지 - 라이센스 요청 테이블 컬럼 ( 일반 사용자 )
export const licenseRequestColumn_user = [
  { field: 'License_Name', header: 'Name' },
  { field: 'Total_Quantity', header: 'Quantity' },
  { field: 'License_Type', header: 'Type' },
  { field: 'License_Limit', header: 'Limit' },
  { field: 'License_Request_Date', header: 'Request Date' },
  { field: 'UID', header: 'UUID' },
  { field: 'Manager_IP', header: 'IP' },
  { field: 'Issue_Mail', header: 'Manager Login ID(Mail)' },
  { field: 'Project', header: 'Project' },
  { field: 'License_Expiration_Date', header: 'Expiration' },
  // { field: 'License_Version', header: 'Version' },
]

//  라이센스 요청 페이지 - 라이센스 요청 테이블 기본선택 컬럼 ( 일반 사용자 )
export const defaultSelectedLicenseRequestColumn = [
  'License_Name', 'Total_Quantity', 'License_Type', 'License_Limit', 'License_Request_Date', 'License_Expiration_Date', 'UID', 'User_Mail', 'User_Name'
]

//  라이센스 요청 페이지 - 라이센스 요청 테이블 컬럼 ( admin )
export const licenseRequestColumn_admin = [
  { field: 'License_Name', header: 'Name' },
  { field: 'Total_Quantity', header: 'Quantity' },
  { field: 'License_Type', header: 'Type' },
  { field: 'License_Limit', header: 'Limit' },
  { field: 'License_Request_Date', header: 'Request Date' },
  { field: 'UID', header: 'UUID' },
  { field: 'Manager_IP', header: 'IP' },
  { field: 'User_Mail', header: 'ZIA Mail' },
  { field: 'User_Name', header: 'User' },
  { field: 'Issue_Mail', header: 'Manager ID' },
  { field: 'Project', header: 'Project' },
  { field: 'Customer', header: 'Customer' },
  // { field: 'License_Version', header: 'Version' },
  { field: 'License_Expiration_Date', header: 'Expiration' },
  // { field: 'License_Start_Date', header: 'Start Date' },
]

//  라이센스 요청 페이지 - 라이센스 요청 테이블 기본선택 컬럼 ( admin )
export const defaultSelectedLicenseIssueHistoryColumn = ['Issue_State', 'License_Issued_Date', 'License_Name', 'Total_Quantity', 'License_Type', 'License_Limit', 'License_Request_Date', 'License_Expiration_Date']

//  라이센스 발급 기록 페이지 - 라이센스 발급 기록 테이블 컬럼 ( 일반 사용자 )
export const licenseIssueHistoryColumn_user = [
  { field: 'License_Name', header: 'Name' },
  { field: 'Issue_State', header: 'State' },
  { field: 'Total_Quantity', header: 'Quantity' },
  { field: 'License_Type', header: 'Type' },
  { field: 'License_Limit', header: 'Limit' },
  { field: 'License_Request_Date', header: 'Request Date' },
  { field: 'License_Issued_Date', header: 'Issued Date' },
  { field: 'License_Expiration_Date', header: 'Expiration' },
  { field: 'UID', header: 'UUID' },
  { field: 'Manager_IP', header: 'IP' },
  { field: 'Issue_Mail', header: 'Manager Login ID(Mail)' },
  { field: 'Project', header: 'Project' },
]

//  라이센스 발급 기록 페이지 - 라이센스 발급 기록 테이블 컬럼 ( admin )
export const licenseIssueHistoryColumn_admin = [
  { field: 'License_Name', header: 'Name' },
  { field: 'Issue_State', header: 'State' },
  { field: 'Total_Quantity', header: 'Quantity' },
  { field: 'License_Type', header: 'Type' },
  { field: 'License_Limit', header: 'Limit' },
  { field: 'License_Request_Date', header: 'Request Date' },
  { field: 'License_Issued_Date', header: 'Issued Date' },
  { field: 'License_Expiration_Date', header: 'Expiration' },
  { field: 'UID', header: 'UUID' },
  { field: 'Manager_IP', header: 'IP' },
  { field: 'User_Mail', header: 'Mail' },
  { field: 'User_Name', header: 'User' },
  { field: 'Issue_Mail', header: 'Manager Login ID(Mail)' },
  { field: 'Project', header: 'Project' },
  { field: 'Customer', header: 'Customer' },
  { field: 'Comment', header: 'Comment' },
  // {field : 'License_Start_Date', header:'Start Date'},
  // {field : 'License_Version', header:'Version'},
]

// export const managerTypeList = ['ZCM', 'ZDM']

// export const default_selected_manager_column = ['sType', 'sManager_UUID', 'nManager_State', 'slast_Communication_Date', 'sLicense_Key']

// export const ManagerListColumn = [
//   { field: 'sManger_IP', header: 'IP' },
//   { field: 'sManager_UUID', header: 'UUID' },
//   { field: 'sType', header: 'Type' },
//   { field: 'sLicense_Key', header: 'License Key' },
//   { field: 'nManager_State', header: 'State' },
//   { field: 'slast_Communication_Date', header: 'last Communication Date' }
// ]

//  Promotion //
//  프로모션 페이지 - 프로모션 테이블 컬럼
export const promotionLicenseColumn = [
  { field: 'Promotion_Code', header: 'Promotion Code' },
  { field: 'State', header: 'State' },
  { field: 'License_Type', header: 'License Type' },
  { field: 'Total_Quantity', header: 'Total Quantity' },
  { field: 'User', header: 'User' },
  { field: 'Mail', header: 'Mail' },
  { field: 'Key', header: 'Key' },
  { field: 'Company', header: 'Company' },
  { field: 'License_Request_Date', header: 'Request Date' },
  { field: 'License_Create_Date', header: 'Create Date' },
  { field: 'License_Expire_Date', header: 'Expire Date' },
  { field: 'Comment', header: 'Comment' },
  { field: 'UUID', header: 'UUID' },
  { field: 'Portal_ID', header: 'Portal ID' },
  { field: 'Organization', header: 'Organization' },
  { field: 'License_Name', header: 'License Name' },
  { field: 'Nation', header: 'Nation' },
]

//  프로모션 페이지 - 프로모션 기본선택 컬럼
export const defaultSelectedPromotionLicenseColumn = [
  'Promotion_Code',
  'State',
  'License_Type',
  'Total_Quantity',
  'User',
  'Mail',
  'Company',
  'License_Request_Date',
  'Comment',
]

//  프로모션 코드 페이지 - 프로모션 코드 테이블 컬럼
export const promotionCodeColumn = [
  { field: 'Promotion_Code', header: 'Promotion Code' },
  { field: 'State', header: 'State' },
  { field: 'License_Type', header: 'License Type' },
  { field: 'Total_Quantity', header: 'Total Quantity' },
  { field: 'Code_Regist_Date', header: 'Regist Date' },
  { field: 'Code_Expire_Date', header: 'Expire Date' },
  { field: 'LastUpdate_Date', header: 'LastUpdate Date' },
  { field: 'Comment', header: 'Comment' },
]

//  프로모션 코드 페이지 - 프로모션 코드 테이블 기본선택 컬럼
export const defaultSelectedPromotionCodeColumn = [
  'Promotion_Code',
  'State',
  'License_Type',
  'Total_Quantity',
  'Comment',
  'Code_Regist_Date',
  'Code_Expire_Date',
]

//  프로모션 코드 페이지 - 프로모션 코드 상태 목록
export const promotionLicensetState = ['approve', 'waiting', 'deny']

//  [ License History Table ]
//  라이센스 페이지 - 라이센스 상태 ( 공통 )
export const licenseHistoryLicenseState: {
  [key: number]: string
} = {
  1: 'Disabled',
  2: 'Activated',
  3: 'Expired'
}
//  라이센스 페이지 - 라이센스 히스토리 테이블 컬럼 ( zem )
export const zemLicenseHistoryColumn = [
  { field: 'Activation', header: 'Activation' },
  { field: 'LicenseType', header: 'License Type' },
  { field: 'SystemName', header: 'System Name' },
  { field: 'SystemUUID', header: 'UUID' },
  { field: 'RegisterDate', header: 'Regist Date' },
  { field: 'LocalUpdateDate', header: 'Update Date ( Local )' },
  { field: 'UpdateDate', header: 'Update Date ( ZIA )' },
  { field: 'Uploader', header: 'Uploader' },
]

//  라이센스 페이지 - 라이센스 히스토리 기본선택 컬럼 ( zem )
export const defaultSelectedZemLicenseHistoryColumn = [
  'Activation', 'SystemName', 'RegisterDate', 'LocalUpdateDate', 'UpdateDate', 'Uploader'
]

//  라이센스 페이지 - 라이센스 히스토리 테이블 컬럼 ( zdm )
export const zdmLicenseHistoryColumn = [

]

//  라이센스 페이지 - 라이센스 히스토리 기본선택 컬럼 ( zdm )
export const defaultSelectedZdmLicenseHistoryColumn = [
]

//  라이센스 페이지 - 라이센스 히스토리 테이블 컬럼 ( zcm )
export const zcmLicenseHistoryColumn = [

]

//  라이센스 페이지 - 라이센스 히스토리 기본선택 컬럼 ( zcm )
export const defaultSelectedZcmLicenseHistoryColumn = [
]