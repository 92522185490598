import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from "react"

import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from '../../../../hooks/useDayJS'
import { useLicense } from "../../../../hooks/useLicense"
import LicenseTable from "../../../user/license/list/Table.LicenseList"

import { LoadingScreen } from '../../../../components/modal/Modal.LoadingScreen'
import { CustomToast } from '../../../../components/toast/Toast'
import LicenseDetailAccordion from "./Accordion.LicenseDetail"
import LicenseHistoryAccordion from "./Accordion.LicenseHistory"

function UserLicenseListPage(props: any) {
  const toast = useRef<Toast>(null)
  const licenseDetail = useRef<HTMLDivElement>(null)
  const { loginCheck } = useAuth()
  const { getToday } = useDayJS()
  const {
    licenseList,
    licenseHistory,
    currentlyAvailableLicenseNow,
    currentlyExpiredLicenseNow,
    getLicenseList,
    getLicenseHistory,
    deleteLicense
  } = useLicense()

  const [isLoading, setIsLoading] = useState(true)
  const [loadingScreenTitle, setLoadingScreenTitle] = useState<string>('License List now Loading...')
  const [selectedLicense, setSelectedLicense] = useState<ILicense | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreenTitle('License List now Loading...')
      setIsLoading(true)
      try {
        await loginCheck()
          .then((result) => {
            getLicenseList()
              .catch((error) => { CustomToast({ toast, sev: 'error', sum: 'Promotion License List Request Fail', det: error?.message ?? 'error', time: 10000 }) })
          })
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])


  useEffect(() => {
    if (selectedLicense && licenseDetail.current) {
      const current = licenseDetail.current
      setTimeout(() => { current.scrollIntoView({ behavior: 'smooth' }) }, 0)
      getLicenseHistory({ type: selectedLicense.License_Type, id: selectedLicense.ID })
        .catch((error) => { CustomToast({ toast, sev: 'error', sum: 'License History Request Fail', det: error?.message ?? 'error', time: 10000 }) })
    }
  }, [selectedLicense])


  return (
    <>
      {isLoading &&
        <LoadingScreen title={loadingScreenTitle} />
      }
      <Card className='w-full'>
        <Toast ref={toast} content={null} />
        <Card className='h-4rem'>
          <div className='flex justify-content-between flex-wrap'>
            <div className='font-bold'>License Info</div>
            <div className='flex font-bold'>
              <div>{props.t('page_last_update_time')} : {getToday({})}</div>
            </div>
          </div>
        </Card>
        <Divider />
        <div className='flex justify-content-between align-content-center'>
          <div className='ml-3'>
            <p className='font-bold'>
              {props.t('license_info_summary1')} : {licenseList.length}
              <span className='text-blue-500'> {props.t('license_info_summary2')}</span> : {currentlyAvailableLicenseNow.length}
              <span className='text-red-500'> {props.t('license_info_summary3')}</span> : {currentlyExpiredLicenseNow.length}
            </p>
          </div>
        </div>
        <Divider />
        <LicenseTable
          userType={props.userType}
          licenseList={licenseList}
          selectedLicense={selectedLicense}
          setSelectedLicense={setSelectedLicense}
          getLicenseList={getLicenseList}
          deleteLicense={deleteLicense}
          setIsLoading={setIsLoading}
          setLoadingScreenTitle={setLoadingScreenTitle} />
        <Divider />
        {selectedLicense &&
          <div ref={licenseDetail}>
            <LicenseDetailAccordion
              t={props.t}
              selectedLicense={selectedLicense}
            />
          </div>}
        {selectedLicense &&
          <div >
            <LicenseHistoryAccordion
              t={props.t}
              selectedLicense={selectedLicense}
              licenseHistory={licenseHistory}
            />
          </div>}
      </Card>
    </>
  )
}

export default UserLicenseListPage
