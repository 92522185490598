import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Tooltip } from "primereact/tooltip"
import { useState } from "react"

import { CustomButton } from "../../../../components/button/Button"
import { defaultSelectedPromotionLicenseColumn, licenseTypeList, licenseTypeObject, promotionLicenseColumn, promotionLicensetState } from "../../../../constants/table"

function PromotionLicenseTable(props: any) {

  const columns = promotionLicenseColumn
  const [selectedColumns, setSelectedColumns] = useState(columns.filter((el) => defaultSelectedPromotionLicenseColumn.includes(el.field)))
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    License_Type: { value: null, matchMode: FilterMatchMode.IN },
    State: { value: null, matchMode: FilterMatchMode.IN },
  })

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field))
    setSelectedColumns(orderedSelectedColumns)
  }

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      License_Type: { value: null, matchMode: FilterMatchMode.IN },
      State: { value: null, matchMode: FilterMatchMode.IN },
    })
    setGlobalFilterValue('')
    setSelectedColumns(columns.filter((el) => defaultSelectedPromotionLicenseColumn.includes(el.field)))
  }

  const renderHeader = () => {
    return (
      <div className="flex flex-warp justify-content-between">
        <div style={{ textAlign: 'left', flex: '1 0 auto' }}>
        </div>
        <div className='flex flex-wrap flex-grow'>
          <div className='mr-3'>
            <CustomButton
              label="Filter Clear"
              styleOption="outlined"
              onClick={initFilter}
            />
          </div>
          <div className='flex flex-wrap'>
            <div className='mr-3' style={{ textAlign: 'left', flex: '1 1 auto' }}>
              <MultiSelect maxSelectedLabels={4} value={selectedColumns} options={columns} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
            </div>
            <div className='flex' style={{ flex: '1 1 auto', flexDirection: 'column', flexWrap: 'wrap' }}>
              <span className="p-input-icon-left" style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-search" style={{ marginRight: '0.5em' }} />
                <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder="Keyword Search" />
              </span>
            </div>
          </div>
        </div>
      </div >
    )
  }

  const rowSelect = (value: any) => { props.setselectedPromotionLicenseList(value) }

  const licenseTypeColumn = (rowData: any) => {
    return <span>{licenseTypeObject[`${rowData.License_Type}`]}</span>
    // return <span>{rowData.License_Type}</span>
  }

  const licenseTypeFilterBody = (options: any) => {
    // return <span className={classNames('font-bold', (options === 'Allow') ? 'text-blue-500' : 'text-red-500')}>{options}</span>
    return <span >{options}</span>
  }

  const requestStateFilterBody = (options: any) => {
    // return <span className={classNames('font-bold', (options === 'Allow') ? 'text-blue-500' : 'text-red-500')}>{options}</span>
    return <span >{options}</span>
  }

  const licenseTypeFilter = (options: any) => {
    return <MultiSelect value={options.value} options={licenseTypeList} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={licenseTypeFilterBody} placeholder="Select License Type" className="p-column-filter" />
  }

  const requestStateFilter = (options: any) => <MultiSelect value={options.value} options={promotionLicensetState} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={requestStateFilterBody} placeholder="Select a Status" className="p-column-filter" />

  const commentBody = (rowData: any) => {
    const comment = rowData.Comment ?? ''
    return <>
      <Tooltip target=".commnet" mouseTrack mouseTrackLeft={10} />
      <div className='commnet white-space-nowrap overflow-hidden text-overflow-ellipsis' data-pr-tooltip={comment} data-pr-showdelay={5} style={{ width: "200px", backgroundColor: 'transparent' }}>{comment}</div>
    </>
  }

  const stateBody = (rowData: any) => {
    const textColor = rowData.State === 'approve' ? 'text-blue-500' : rowData.State === 'deny' ? 'text-red-500' : 'text-yellow-500'
    return <span className={`font-bold ${textColor}`}>{rowData.State}</span>
  }

  return (
    <div className="card">
      <DataTable
        value={props.promotionLiceseList} header={() => renderHeader()} tableStyle={{ minWidth: '50rem' }}
        size='small' stripedRows removableSort
        filters={filters}
        globalFilterFields={selectedColumns.map((el) => el.field)}
        emptyMessage="No Promotion License found."
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
        reorderableColumns
        selection={props.selectedPromotionLicenseList} selectionPageOnly onSelectionChange={e => rowSelect(e.value)}
        onValueChange={filteredValues => setFilteredValue(filteredValues)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
        {selectedColumns.map((col) => {
          if (col.field === 'License_Type') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={licenseTypeColumn}
              filter filterElement={licenseTypeFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
            />
          }
          if (col.field === 'State') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={stateBody}
              filter filterElement={requestStateFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
            />
          }
          if (col.field === 'Comment') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={commentBody}
            />
          }
          return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} sortable />
        })}
      </DataTable>
    </div>
  )
}

export default PromotionLicenseTable