import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { Toast } from "primereact/toast"
import { useEffect, useRef, useState } from "react"
import { LoadingScreen } from "../../../../components/modal/Modal.LoadingScreen"
import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from "../../../../hooks/useDayJS"
import { usePromotion } from "../../../../hooks/usePromotion"

import { CustomToast } from "../../../../components/toast/Toast"
import PromotionLicenseDecideModal from "./DecideModal"
import PromotionLicenseTable from './Table'

function AdminPromotionListPage(props: any) {
  const toast = useRef<Toast>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPromotionLicenseList, setselectedPromotionLicenseList] = useState([])

  const { loginCheck } = useAuth()
  const { getToday } = useDayJS()
  const {
    promotionLiceseList,
    getPromotionLicenseRequsetList,
    decidePromotionLicenseRequest,
    deletePromotionLicense
  } = usePromotion()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await loginCheck()
          .then((result) => {
            getPromotionLicenseRequsetList()
              .catch((error) => { CustomToast({ toast, sev: 'error', sum: 'Promotion License List Request Fail', det: error?.message ?? 'error', time: 10000 }) })
          })
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      {isLoading &&
        <LoadingScreen
          title="Promotion License List now Loading..."
        />
      }
      <Card className='w-full'>
        <Toast ref={toast} content={null} />
        <Card className='h-4rem'>
          <div className='flex justify-content-between flex-wrap'>
            <div className='font-bold'>Promotion License List</div>
            <div className='flex font-bold'>
              <div>{props.t('page_last_update_time')} : {getToday({})}</div>
            </div>
          </div>
        </Card>
        <Divider />
        <div className='flex justify-content-between align-content-center flex-wrap'>
          <div className='ml-3'>
            <p className='font-bold'>
              total : {promotionLiceseList.length}
              <span className='text-blue-500'> approve</span> : {promotionLiceseList.filter((el: any) => el.State === 'approve').length}
              <span className='text-yellow-500'> waiting</span> : {promotionLiceseList.filter((el: any) => el.State === 'waiting').length}
              <span className='text-red-500'> deny</span> : {promotionLiceseList.filter((el: any) => el.State === 'deny').length}
            </p>
          </div>
          <div className='ml-3'>
            <PromotionLicenseDecideModal
              loginCheck={loginCheck}
              decidePromotionLicenseRequest={decidePromotionLicenseRequest}
              selectedPromotionLicenseList={selectedPromotionLicenseList}
              setselectedPromotionLicenseList={setselectedPromotionLicenseList}
              deletePromotionLicense={deletePromotionLicense}
              getPromotionLicenseRequsetList={getPromotionLicenseRequsetList}
            />
          </div>
        </div>
        <Divider />
        <PromotionLicenseTable
          promotionLiceseList={promotionLiceseList}
          selectedPromotionLicenseList={selectedPromotionLicenseList}
          setselectedPromotionLicenseList={setselectedPromotionLicenseList}
        />
      </Card>
    </>
  )
}

export default AdminPromotionListPage
