import React, { useState, useRef } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'

import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'
import { useDayJS } from '../../../../hooks/useDayJS'

import { CustomButton } from '../../../../components/button/Button'
import { CustomToast } from '../../../../components/toast/Toast'
import { licenseTypeList_object } from '../../../../constants/dropdown'

export const PromotionCodeEditModal = (props: any) => {

  const toast = useRef<Toast>(null)

  const { exChangeFormat } = useDayJS()

  const [showModal, setShowModal] = useState(false)
  const [setIsLoading, setSetIsLoading] = useState(false)

  const defaultValues = {
    PROMOTION_CODE: props.rowData.Promotion_Code,
    LICENSE_VERSION: props.rowData.License_Version,
    LICENSE_TYPE: props.rowData.License_Type,
    LICENSE_COUNT: props.rowData.Total_Quantity,
    REGIST_DATE: props.rowData.Code_Regist_Date,
    EXPIRE_DATE: new Date(props.rowData.Code_Expire_Date),
    COMMENT: props.rowData.Comment,
    STATE: props.rowData.State
  }

  const openModal = () => { reset(defaultValues); setSetIsLoading(false); setShowModal(true) }
  const onHide = () => { setShowModal(false) }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ defaultValues })

  const header = () => {
    const textColor = props.rowData.State === 'available' ? 'text-blue-500' : 'text-red-500'
    return <div>
      <div>Promotion Code Edit</div>
      <div>[ Code : {props.rowData.Promotion_Code} ]</div>
      <div>State : <span className={`font-bold ${textColor}`}>{props.rowData.State}</span></div>
    </div>
  }

  const footer = () => {
    return (
      <div>
        <CustomButton label="cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
      </div>
    )
  }


  const getFormErrorMessage = (name: string) => {
    const errorMessages = errors as { [key: string]: FieldError }
    return errorMessages[name] ? <small className="p-error">{errorMessages[name].message}</small> : <small className="p-error"></small>
  }

  const onSubmit = async (data: any) => {
    data.LICENSE_VERSION = data.LICENSE_TYPE === 1 ? 4 : (2 <= data.LICENSE_TYPE && data.LICENSE_TYPE <= 4) ? 7 : 6
    data.ID = props.rowData.ID
    data.EXPIRE_DATE = exChangeFormat({ date: data.EXPIRE_DATE, format: 'YYYY-MM-DD 23:59:59' })
    setSetIsLoading(true)
    await props.editPromotionCode({ data })
      .then((r: any) => {
        if (r?.state === 'success') {
          CustomToast({ toast, sev: 'success', sum: 'promotion code edit success', det: r.message, time: 5000 })
          props.getPromotionCodeList()
          reset()
          onHide()
        }
        else {
          CustomToast({ toast, sev: 'error', sum: 'promotion code edit fail', det: r.message, time: 5000 })
        }
      })
      .catch((e: any) => {
        CustomToast({ toast, sev: 'error', sum: 'promotion code edit fail', det: e.message, time: 5000 })
      })
      .finally(() => setSetIsLoading(false))
  }

  return (
    <>
      <Toast ref={toast} content={null} />
      <CustomButton
        icon="pi pi-cog"
        className='p-button-info pl-0 pr-0'
        tooltip="edit"
        tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
        onClick={() => openModal()}
      />
      <Dialog header={header} content={null}
        visible={showModal}
        blockScroll={true}
        style={{ width: '50vw' }}
        onHide={() => onHide()}
        footer={footer}
      >
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} className="promotion p-fluid">
          <div className={classNames("field", { 'mt-5': watch('LICENSE_TYPE') })}>
            <span className="p-float-label">
              <Controller name="LICENSE_TYPE" control={control}
                rules={{ required: 'License Type is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown id={field.name} value={field.value}
                    onChange={(e) => field.onChange(e.value)} options={licenseTypeList_object} optionLabel="name"
                    className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="LICENSE_TYPE" className={classNames({ 'p-error': errors.LICENSE_TYPE })}>License Type*</label>
            </span>
            {getFormErrorMessage('LICENSE_TYPE')}
          </div>
          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="PROMOTION_CODE" control={control}
                rules={{
                  required: 'Promotion Code is required.',
                }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} disabled />
                )} />
              <label htmlFor="PROMOTION_CODE" className={classNames({ 'p-error': errors.PROMOTION_CODE })}>Promotion Code*</label>
            </span>
            {getFormErrorMessage('PROMOTION_CODE')}
          </div>
          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="LICENSE_COUNT" control={control}
                rules={{
                  required: 'License Count is required.',
                  pattern: { value: /^\d+$/, message: 'License Count can only be numeric' },
                  validate: value => parseInt(value, 10) >= 1 || 'License Count must be at least 1.'
                }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="LICENSE_COUNT" className={classNames({ 'p-error': !!errors.LICENSE_COUNT })}>License Count*</label>
            </span>
            {getFormErrorMessage('LICENSE_COUNT')}
          </div>
          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="EXPIRE_DATE" control={control}
                rules={{ required: 'License Expiration Date is required.' }}
                render={({ field, fieldState }) => (
                  <Calendar id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.invalid })}
                    onChange={(e) => field.onChange(e.value)} dateFormat="dd/mm/yy" mask="99/99/9999" showIcon />
                )} />
              <label htmlFor="EXPIRE_DATE" className={classNames({ 'p-error': !!errors.EXPIRE_DATE })}>Expiration date</label>
            </span>
            {getFormErrorMessage('EXPIRE_DATE')}
          </div>
          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="COMMENT" control={control}
                rules={{ required: 'Comment is required.' }}
                render={({ field, fieldState }) => (
                  // <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                  <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} rows={5} cols={30} autoResize />
                )} />
              <label htmlFor="COMMENT" className={classNames({ 'p-error': !!errors.COMMENT })}>Comment*</label>
            </span>
            {getFormErrorMessage('COMMENT')}
          </div>
          <Divider type="dashed" />
          <Button type="submit" label="Submit" className="mt-2" iconPos="right" loading={setIsLoading} disabled={setIsLoading} />
        </form>
      </Dialog>
    </>
  )
}
