import { FilterMatchMode, FilterOperator } from "primereact/api"
import { Column } from "primereact/column"
import { DataTable, DataTableFilterMeta } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect"
import { classNames } from "primereact/utils"
import { useState } from "react"
import { CustomButton } from "../../../../components/button/Button"
import { defaultSelectedZemLicenseHistoryColumn, licenseHistoryLicenseState, zemLicenseHistoryColumn } from "../../../../constants/table"
import { useDayJS } from "../../../../hooks/useDayJS"
import { useExport } from "../../../../hooks/useExport"

function ZEMLicenseHistoryTable(props: any) {
  const columns = zemLicenseHistoryColumn

  const { getToday, exChangeFormat } = useDayJS()
  const { exportExcel } = useExport()

  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [selectedColumns, setSelectedColumns] = useState<TableColums[]>(columns.filter((el: TableColums) => defaultSelectedZemLicenseHistoryColumn.includes(el.field)))
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    License_Type: { value: null, matchMode: FilterMatchMode.IN },
    License_Expiration_Date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  })

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value
    let _filters = { ...filters }

    // @ts-ignore
    _filters['global'].value = value;

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      License_Type: { value: null, matchMode: FilterMatchMode.IN },
      License_Expiration_Date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    })
    setGlobalFilterValue('')
    setSelectedColumns(columns.filter((el: TableColums) => defaultSelectedZemLicenseHistoryColumn.includes(el.field)))
  }

  //  header
  const renderHeader = () => {
    return (
      <div>
        <div className="flex flex-wrap justify-content-between">
          <div className='mr-3' style={{ textAlign: 'left', flex: '1 0 auto' }}>
            <CustomButton
              // label='Export'
              icon='pi pi-file-excel'
              className='p-button-success'
              tooltip="Export to Excel"
              onClick={() => exportExcel({ data: filteredValue.length > 0 ? filteredValue : props.historyList, fileName: `${props.selectedLicense.License_Name}_History${getToday({}).replace(/[^0-9]+/g, '')}`, header: selectedColumns })}
              tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
              // @ts-ignore <- 이거 안넣어주면 에러발생함
              disabled={filters.global.value && (filteredValue.length === 0)}
            />
          </div>
          <div className='flex flex-wrap'>
            <div className='mr-3' style={{ textAlign: 'left', flex: '1 0 auto' }}>
              <CustomButton
                label="Filter Clear"
                styleOption="outlined"
                onClick={initFilter}
              />
            </div>
            <div className='flex'>
              <div className='mr-3' style={{ textAlign: 'left', flex: '1 0 auto' }}>
                <MultiSelect maxSelectedLabels={4} value={selectedColumns} options={zemLicenseHistoryColumn} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
              </div>
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder="Keyword Search" />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //  table header MultiSelect 관련
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field))
    setSelectedColumns(orderedSelectedColumns)
  }

  //  default Date Body
  const dateBodyTemplate = (rowData: any, classify: string) => {
    const date = exChangeFormat({ date: (rowData[classify] as string), format: 'YYYY-MM-DD HH:mm:ss' })
    const short = date.split(' ')[0] !== '-' ? (date as string) : 'unlimit'
    return (
      <div>{short}</div>
    )
  }

  //  activation body 
  const activationBody = (rowData: any) => {
    const state = licenseHistoryLicenseState[rowData.Activation]
    const textClass = classNames({
      'text-red-500': state === 'Disabled',
      'text-blue-500': state === 'Activated',
      'text-gray-500': state === 'Expired'
    })

    return (
      <div className='flex justify-content-center'><span className={textClass}>{state}</span></div>
    )
  }

  return (
    <>
      <DataTable
        //  테이블 표기 데이터 | 테이블 헤더 | 테이블 스타일 추가
        value={props.historyList} header={renderHeader} tableStyle={{ minWidth: '50rem' }}
        //  글로벌 필터
        globalFilterFields={selectedColumns.map((el: TableColums) => el.field)}
        //  테이블 크기 | 테이블 줄무늬 추가 | 컬럼 정렬 추가
        size='small' stripedRows removableSort
        //  검색 필터 옵션 | 데이터 없을시 보여주는 메시지 | 컬럼 헤더 위치 이동 가능
        filters={filters} emptyMessage="No registered license history." reorderableColumns
        //  페이지 네이션 추가
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
        //  필터 데이터 변경시 동작
        onValueChange={filteredValues => setFilteredValue(filteredValues)}
      >
        {selectedColumns.map((col) => {
          if (col.field === 'RegisterDate' || col.field === 'LocalUpdateDate' || col.field === 'UpdateDate') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={(e) => dateBodyTemplate(e, col.field)} sortable />
          }
          else if (col.field === 'LicenseType') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={<div>ZEM</div>} />
          }
          else if (col.field === 'Activation') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} headerClassName='w-6rem' body={(e) => activationBody(e)} sortable />
          }
          else return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} sortable />
        })}
      </DataTable>
    </>
  )
}

export default ZEMLicenseHistoryTable