export const URLLIST = {
  //  로그인
  'LOGIN': `https://www.ziacloud.net:10001/v1/auth/login?admin={ADMIN}`,
  //  세션 체크(유효성)
  'LOGIN_CHECK': `https://www.ziacloud.net:10001/v1/auth/session`,
  //  라이센스 목록 조회
  'LICENSE_LIST': `https://www.ziacloud.net:10001/v1/license`,
  //  라이센스 사용기록(전체)
  'LICENSE_HISTORY_ALL': `https://www.ziacloud.net:10001/v1/license`,
  //  라이센스 사용기록(단일)
  'LICENSE_HISTORY': `https://www.ziacloud.net:10001/v1/license/history?type={TYPE}&id={ID}`,
  //  라이센스 사용기록 추가
  'LICENSE_HISTORY_INSERT': `https://www.ziacloud.net:10001/v1/license`,
  //  라이센스 PDF 파일다운르도
  'LICENSE_PDFFILE_DOWNLOAD': `https://www.ziacloud.net:10001/v1/license`,
  //  라이센스 정보 수정
  'LICENSE_EDIT': `https://www.ziacloud.net:10001/v1/license/{ID}`,
  //  라이센스 발급 요청
  'LICENSE_ISSUE_REQUEST': `https://www.ziacloud.net:10001/v1/license/issue/request`,
  //  라이센스 발급 요청 목록
  'LICENSE_ISSUE_REQUEST_LIST': `https://www.ziacloud.net:10001/v1/license/issue`,
  //  라이센스 발급 결정(승인/거절)
  'LICENSE_ISSUE_DECIDE': `https://www.ziacloud.net:10001/v1/license/issue/response`,
  //  라이센스 발급 요청 삭제
  'LICENSE_ISSUE_REQUSET_DELETE': `https://www.ziacloud.net:10001/v1/license/issue/{ID}`,
  //  라이센스 발급 이력
  'LICENSE_ISSUE_HISTORY': `https://www.ziacloud.net:10001/v1/license/issue/history`,
  //  라이센스 삭제
  'LICENSE_DEIETE': `https://www.ziacloud.net:10001/v1/license/{ID}`,
  //  라이센스 발급 PDF 다운로드
  'LICENSE_PDF_DOWNLOAD': `https://www.ziacloud.net:10001/v1/license/download/pdf`,
  //  프로모션 라이센스 목록 조회 (발급, 승인, 거절)
  'PROMOTION_LICENSE_LIST': `https://www.ziacloud.net:10001/v1/promotion`,
  //  프로모션 라이센스 발급 요청
  'PROMOTION_LICENSE_ISSUE_REQUEST': `https://www.ziacloud.net:10001/v1/promotion/request`,
  //  프로모션 라이센스 발급 승인/거절
  'PROMOTION_LICENSE_REQUEST_DECIDE': 'https://www.ziacloud.net:10001/v1/promotion/response',
  //  프로모션 라이센스 or 프로모션 라이센스 발급요청 삭제
  'PROMOTION_LICENSE_DELETE': 'https://www.ziacloud.net:10001/v1/promotion/{ID}',
  //  프로모션 코드 목록 조회
  'PROMOTION_CODE_LIST': `https://www.ziacloud.net:10001/v1/promotion/code`,
  //  프로모션 코드 등록
  'PROMOTION_CODE_ADD': `https://www.ziacloud.net:10001/v1/promotion/code`,
  //  프로모션 코드 삭제
  'PROMOTION_CODE_DELETE': `https://www.ziacloud.net:10001/v1/promotion/code/{ID}`,
  //  프로모션 코드 수정
  'PROMOTION_CODE_EDIT': `https://www.ziacloud.net:10001/v1/promotion/code`,
  //  라이센스 Activation 메일 인증코드 요청
  'ACTIVATION_VERIFY_CODE_REQUEST_TO_MAIL': `https://www.ziacloud.net:10001/v1/auth/activation/code`,
  //  라이센스 Activation 메일 인즏
  'ACTIVATION_MAIL_VERIFY': `https://www.ziacloud.net:10001/v1/auth/activation`,
  //  라이센스 Activation 결과 메일로 전송
  'ACTIVATION_RESULT_SEND_TO_MAIL': `https://www.ziacloud.net:10001/v1/auth/activation/send/result`,
}