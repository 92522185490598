import AppRoutes from "./App.router"

function App() {
  return (
    <div className="App" >
      {/* style={{ minWidth: '740px' }} */}
      <AppRoutes />
    </div>
  )
}

export default App
