import { useRef, useState } from 'react'
import "./Modal.LicenseIssueDecide.css"

import { Card } from 'primereact/card'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Toast } from 'primereact/toast'

import { InputTextarea } from 'primereact/inputtextarea'
import { CustomButton } from '../button/Button'
import { CustomToast } from '../toast/Toast'

export const LicenseIssueDecideModal = (props: any) => {
  const toast = useRef<Toast>(null)
  const [openType, setOpenType] = useState('')
  const [requestLicenseModal, setRequestLicenseModal] = useState(false)

  const openModal = async (type: string) => {
    setOpenType(type)
    setRequestLicenseModal(true)
  }

  const onHide = () => { setRequestLicenseModal(false) }

  //  라이센스 발급 승인
  const approveRequest = async () => {
    props.setIsLoading(true)
    props.setLoadingMessage('License Issue Request now Approving...')
    let _selectedLicenseRequest: any[] = []
    let successfulResults: { [key: string]: any }[] = []
    let failedResults: { [key: string]: any }[] = []
    try {
      await props.loginCheck()
        .then(async (result: any) => {
          _selectedLicenseRequest = props.selectedLicenseRequest

          const approvePromises = _selectedLicenseRequest.map((el: any) => { return props.decideLicenseRequest({ id: el.ID, decide: true }) })
          const results = await Promise.all(approvePromises)

          results.forEach((result: any, index: number) => {
            const el: any = _selectedLicenseRequest[index]
            if (result?.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: `[ ${el.License_Name} ] license issue success`, det: `license issue success` })
              successfulResults.push(el)
            } else {
              CustomToast({ toast, sev: 'error', sum: `[ ${el.License_Name} ] license issue fail`, det: result?.message })
              failedResults.push(el)
            }
          })

          _selectedLicenseRequest = successfulResults

          const remainingLicenseIssueRequestList = props.licenseIssueRequestList.filter((itemA: any) => {
            return !_selectedLicenseRequest.some((itemB: any) => {
              return itemB.ID === itemA.ID
            })
          })

          props.setLicenseIssueRequestList(remainingLicenseIssueRequestList)
          props.setSelectedLicenseRequest(failedResults)
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'license issue fail', det: error.message, time: 5000 })
    } finally {
      props.setIsLoading(false)
      props.setLoadingMessage('License Request List now Loading...')
      if (!failedResults.length) { onHide() }
    }
  }

  //  라이센스 발급 거부
  const declineRequest = async () => {
    props.setIsLoading(true)
    props.setLoadingMessage('License Issue Request now Declining...')
    let _selectedLicenseRequest: any[] = []
    let successfulResults: { [key: string]: any }[] = []
    let failedResults: { [key: string]: any }[] = []
    try {
      await props.loginCheck()
        .then(async (result: any) => {
          _selectedLicenseRequest = props.selectedLicenseRequest
          const declinePromises = _selectedLicenseRequest.map((el: any) => { return props.decideLicenseRequest({ id: el.ID, decide: false, comment: el.Comment }) })
          const results = await Promise.all(declinePromises)

          results.forEach((result: any, index: number) => {
            const el: any = _selectedLicenseRequest[index]
            if (result?.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: `[ ${el.License_Name} ] license issue request deny success`, det: `license issue request deny success` })
              successfulResults.push(el)
            } else {
              CustomToast({ toast, sev: 'error', sum: `[ ${el.License_Name} ] license issue request deny fail`, det: result?.message })
              failedResults.push(el)
            }
          })

          _selectedLicenseRequest = successfulResults

          const remainingLicenseIssueRequestList = props.licenseIssueRequestList.filter((itemA: any) => {
            return !_selectedLicenseRequest.some((itemB: any) => {
              return itemB.ID === itemA.ID
            })
          })

          props.setLicenseIssueRequestList(remainingLicenseIssueRequestList)
          props.setSelectedLicenseRequest(failedResults)
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'license issue request deny fail', det: error.message, time: 5000 })
    } finally {
      props.setIsLoading(false)
      props.setLoadingMessage('License Request List now Loading...')
      console.log(failedResults)
      if (!failedResults.length) { onHide() }
    }
  }

  const removeItemToSelected = (el: any) => {
    const updatedData = props.selectedLicenseRequest.filter((item: any) => item.ID !== el.ID)
    props.setSelectedLicenseRequest(updatedData)
    if (!updatedData.length) onHide()
  }

  const header = () => {
    return (
      <>
        {openType === 'issue' &&
          <div className='ml-1'>
            <div>License Request Approve List</div>
            <div className='mt-1'><span className="text-base">{props.selectedLicenseRequest.length} item(s) selected.</span></div>
          </div>}
        {openType === 'decline' &&
          <div className='ml-1'>
            <div>License Request Decline List</div>
            <div className='mt-1'><span className="text-base">{props.selectedLicenseRequest.length} item(s) selected.</span></div>
          </div>}
      </>
    )
  }

  const footer = () => {
    return (
      <div>
        <CustomButton label="cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
        {openType === 'issue' &&
          <CustomButton label="Approve All" icon="pi pi-check" onClick={() => approveRequest()} autoFocus loading={props.isLoading} disabled={props.isLoading} />
        }
        {openType === 'decline' &&
          <CustomButton label="Decline All" icon="pi pi-times-circle" onClick={() => declineRequest()} autoFocus loading={props.isLoading} disabled={props.isLoading}
          />}
      </div>
    )
  }


  return (
    <>
      <Toast ref={toast} content={null} />
      <div className='flex'>
        <div>
          <CustomButton
            label='approve'
            className='p-button-info w-6rem pl-0 pr-0'
            tooltip="Approve license(s)"
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => openModal('issue')}
            disabled={!props.selectedLicenseRequest.length}
          />
        </div>
        <div className='ml-2'>
          <CustomButton
            label='deny'
            className='p-button-warning w-6rem pl-0 pr-0'
            tooltip="Decline License(s)"
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => openModal('decline')}
            disabled={!props.selectedLicenseRequest.length}
          />
        </div>
      </div>

      <Dialog
        header={header}
        visible={requestLicenseModal}
        blockScroll={true}
        style={{ width: '50vw' }}
        onHide={() => onHide()}
        footer={footer}
        content={null}
      >
        <Divider />
        {props.selectedLicenseRequest.map((el: any) => {
          return (
            <div key={el.ID}>
              <Card>
                <div className='flex justify-content-between'>
                  <div className="flex align-items-center">
                    <span className='font-bold'>{el.License_Name}</span>
                  </div>
                  <CustomButton
                    icon="pi pi-times"
                    severity='danger'
                    tooltip="Exclude"
                    className="w-2rem h-1rem"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                    onClick={() => removeItemToSelected(el)}
                    disabled={!props.selectedLicenseRequest.length}
                  />
                </div>
                <Divider />
                <div>
                  <div className='flex mb-2'>
                    <div className='w-5rem'><span className='font-bold'>Quantity</span></div>
                    <div><span>{el.Total_Quantity} EA</span></div>
                  </div>
                  <div className='flex mb-2'>
                    <div className='w-5rem'><span className='font-bold'>Type</span></div>
                    <div><span>{el.License_Type}</span></div>
                  </div>
                  <div className='flex mb-2'>
                    <div className='w-5rem'><span className='font-bold'>Mail</span></div>
                    <div><span>{el.User_Mail}</span></div>
                  </div>
                  <div className='flex mb-2'>
                    <div className='w-5rem'><span className='font-bold'>Name</span></div>
                    <div><span>{el.User_Name}</span></div>
                  </div>
                  <div>
                    <div className='w-5rem mb-2'><span className='font-bold'>Comment</span></div>
                    <InputTextarea className="w-full" autoResize onChange={(e) => { el.Comment = e.target.value }} />
                  </div>
                </div>
              </Card>
              <Divider />
            </div>
          )
        })}
      </Dialog>
    </>
  )
}
