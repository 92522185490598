import { Accordion, AccordionTab } from "primereact/accordion"
import { Calendar } from "primereact/calendar"
import { Card } from "primereact/card"
import { Checkbox } from "primereact/checkbox"
import { Divider } from "primereact/divider"
import { OverlayPanel } from "primereact/overlaypanel"
import { Toast } from "primereact/toast"
import { Nullable } from "primereact/ts-helpers"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CustomButton } from "../../../../components/button/Button"
import PieChart from "../../../../components/chart/pie"
import { CustomToast } from "../../../../components/toast/Toast"
import { licenseTypeList, promotionCodeTypeList } from "../../../../constants/table"
import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from "../../../../hooks/useDayJS"
import { usePromotion } from "../../../../hooks/usePromotion"

function AdminPromotionDashBoardPage(props: any) {
  const toast = useRef<Toast>(null)
  const op2 = useRef<OverlayPanel>(null)
  const navigate = useNavigate()
  const { getToday, getDate, exChangeFormat } = useDayJS()
  const { loginCheck } = useAuth()
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null)
  const [datesType, setDatesType] = useState<string>('')
  const [now, setNow] = useState(getToday({}))
  const {
    codeState,
    promotionCodePerUseage,
    promotionLiceseList,
    promotionCodeList,
    promotionLicenseApprovedToday,
    promotionLicenseRequestToday,
    promotionLicenseWaiting,
    promotionLicenseCategorizationByLicenseTypeList,
    promotionLicenseCategorizationList,
    getPromotionCodeList,
    getPromotionLicenseRequsetList,
    setPromotionLicenseDashBoardDatePeriod,
    getPromotionCodeOnlyList
  } = usePromotion()

  const summaryData = [
    { title: 'Promotion License', count: promotionLiceseList.length, link: '/admin/dashboard/promotion/list' }, //  전체 프로모션 라이센스
    { title: 'Promotion Code', count: promotionCodeList.length, link: '/admin/dashboard/promotion/code' },  //  프로모션 코드
    { title: 'Request today', count: promotionLicenseRequestToday.length, link: '/admin/dashboard/promotion/list' },  //  오늘 프로모션 요청 수량
    { title: 'Approved today', count: promotionLicenseApprovedToday.length, link: '/admin/dashboard/promotion/list' },//  오늘 프로모션 승인 수량
    { title: 'Wait for approval', count: promotionLicenseWaiting.length, link: '/admin/dashboard/promotion/list' },// 오늘자 기준 승인 대기
  ]

  const toggleDataTable = (event: any) => { op2.current?.toggle(event) }
  const onDateChange = (type: any) => {
    setDatesType(type)
    setDates([getDate({ date: now, type }).toDate(), new Date])
  }

  useEffect(() => {
    let isMounted = true; // 추가
    loginCheck()
      .then((result: any) => {
        if (result.state === 'fail') { throw new Error(result.message) }
        setNow(getToday({}))
        getPromotionLicenseRequsetList()
          .catch((error: any) => { CustomToast({ toast, sev: 'error', sum: 'Failed to get promotion license request list.', det: `${error.message}`, time: 5000 }) })
        getPromotionCodeList()
          .catch((error: any) => { CustomToast({ toast, sev: 'error', sum: 'Failed to get promotion code list.', det: `${error.message}`, time: 5000 }) })
      })
      .catch((error: any) => {/** 로그인 관련 에러는 이미 다 처리되어 있음 */ })

    return () => { isMounted = false }; // 클린업 함수 추가
  }, []) // 의존성 배열에서 loginCheck 제거
  return (
    <>
      <Toast ref={toast} content={null} />
      <Card className='w-full'>
        <div className="grid">
          <div className='col-12'>
            <Card>
              <div className='flex justify-content-between'>
                <span className='font-bold'>Promotion License DashBoard</span>
                <span className=''>마지막 갱신 : {now}</span>
              </div>
            </Card>
          </div>

          {/* 최상단 요약 */}
          {summaryData.map((data, index) => (
            <div className='col-12 md:col-6 lg:col-6 xl:col-3' >
              <Card className='mb-0' key={index}>
                <span className="block text-left text-500 font-medium mb-2">{data.title}</span>
                <Divider className='mt-0' />
                <div className='flex justify-content-between mb-3'>
                  <div className="text-900 font-medium text-xl">{data.count} EA</div>
                  <div className="flex align-items-center justify-content-center summaryDataLink">
                    <i className="pi pi-angle-double-right text-xl" onClick={() => { navigate(data.link) }} />
                  </div>
                </div>
                <span className="text-green-500 font-medium"></span>
                <span className="text-500"></span>
              </Card>
            </div>
          ))}
          <Divider />
          <div className="col-12">
            <Card>
              <div className='flex justify-content-between'>
                <div className="flex align-items-center">
                  <span className='font-bold'>Period : {dates === null ? 'All' : `${exChangeFormat({ date: dates![0], format: 'YYYY-MM-DD' })} ~ ${exChangeFormat({ date: dates![1], format: 'YYYY-MM-DD' })}`} ( total : {promotionLicenseCategorizationByLicenseTypeList.reduce((acc, cur) => acc + cur.value, 0)} )</span>
                </div>
                <div><CustomButton type="button" icon="pi pi-cog" onClick={toggleDataTable} styleOption="text rounded" /></div>
              </div>
            </Card>
            <OverlayPanel ref={op2} appendTo={typeof window !== 'undefined' ? document.body : null} showCloseIcon id="overlay_panel" style={{ width: '330px' }}>
              <div className='grid'>
                <div className='col-12'>기간설정</div>
                <div className="col-12 flex flex-wrap justify-content-center">
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient1" name="1 year" value="beforOneYear" onChange={(e) => onDateChange(e.value)} checked={datesType === 'beforOneYear'} />
                    <label htmlFor="ingredient1" className="mx-1">1 year</label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient2" name="1 month" value="beforOneMonth" onChange={(e) => onDateChange(e.value)} checked={datesType === 'beforOneMonth'} />
                    <label htmlFor="ingredient2" className="mx-1">1 month</label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient3" name="1 week" value="beforOneWeek" onChange={(e) => onDateChange(e.value)} checked={datesType === 'beforOneWeek'} />
                    <label htmlFor="ingredient3" className="mx-1">1 week</label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient4" name="today" value="today" onChange={(e) => onDateChange(e.value)} checked={datesType === 'today'} />
                    <label htmlFor="ingredient4" className="ml-1">today</label>
                  </div>
                </div>
              </div>
              <Divider />
              <div>
                <Calendar className='w-full' inputClassName="text-center" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput dateFormat="yy/mm/dd" />
                <Divider />
                <div className='flex justify-content-between'>
                  <CustomButton className="h-1rem" type="button" label="apply" onClick={(e: any) => { setPromotionLicenseDashBoardDatePeriod(dates); toggleDataTable(e) }} />
                  <CustomButton className="h-1rem" type="button" label="clear" onClick={() => { setDates(null); setDatesType('') }} />
                </div>
              </div>
            </OverlayPanel>
          </div>
          {/* 프로모션 라이센스 요약 */}
          <div className="col-12">
            <Accordion activeIndex={0}>
              <AccordionTab header="Promotion License Summary" headerClassName='text-left'>
                <div className='grid'>
                  <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="Promotion License issuance configuration"
                      seriesName="License"
                      legendData={licenseTypeList}
                      mainData={promotionLicenseCategorizationList}
                    />
                  </div>
                  <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="Promotion License issuance type"
                      seriesName="License"
                      legendData={['ZCM', 'ZDM', 'ZEM']}
                      mainData={promotionLicenseCategorizationByLicenseTypeList}
                    />
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
          {/* 프로모션 코드 요약 */}
          <div className="col-12">
            <Accordion activeIndex={0}>
              <AccordionTab header="Promotion Code Summary" headerClassName='text-left'>
                <div className='grid'>
                  <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="Promotion Code State"
                      seriesName="Code"
                      legendData={promotionCodeTypeList}
                      mainData={codeState}
                    />
                  </div>
                  <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="Promotion code type"
                      seriesName="Code"
                      legendData={['ZCM', 'ZDM', 'ZEM']}
                      mainData={promotionLicenseCategorizationByLicenseTypeList}
                    />
                  </div>
                  {/* <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="Quantity issued per promotion code"
                      seriesName="Code"
                      legendData={getPromotionCodeOnlyList}
                      mainData={promotionCodePerUseage}
                    />
                  </div> */}
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </Card >
    </>
  )
}

export default AdminPromotionDashBoardPage
