import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { URLLIST } from "../constants/url"
import { getAPI, postAPI } from "../services/api"

export const useAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAdmin = location.pathname.includes('/admin') ? 'admin' : 'user'

  const navigateToLogin = () => {
    sessionStorage.removeItem('id')
    if (isAdmin === 'admin') navigate('/admin/login')
    else navigate('/login')
  }

  const loginCheck = useCallback(async () => {
    const path = URLLIST['LOGIN_CHECK']
    return await getAPI({ path })
      .then((result) => { return result })
      .catch((error) => {
        alert('세션이 만료되었습니다. 로그인 화면으로 이동합니다')
        navigateToLogin()
        // throw error
      })
  }, [])

  const login = async ({ loginData }: { loginData: { mail: string, password: string } }) => {
    const path = URLLIST['LOGIN']
    return await postAPI({ path, body: loginData })
      .then((result) => {
        sessionStorage.setItem("id", result.data.id)
        if (loginData.mail === 'ziaadmin@zconverter.com') navigate("/admin/dashboard/license")
        else navigate("/v1/dashboard/license")
        return result
      })
      .catch((error) => { throw error })
  }

  const requestVerifiCode = async ({ mail }: { mail: string }) => {
    const path = URLLIST['ACTIVATION_VERIFY_CODE_REQUEST_TO_MAIL']
    return await postAPI({ path, body: { mail } })
      .then((result) => { return result })
      .catch((error) => { throw error })
  }

  const requestVerifi = async ({ mail, code }: { mail: string, code: string }) => {
    const path = URLLIST['ACTIVATION_MAIL_VERIFY']
    return await postAPI({ path, body: { mail, code } })
      .then((result) => { return result })
      .catch((error) => { throw error })
  }

  const logout = () => { navigateToLogin() }

  return {
    loginCheck,
    login,
    logout,
    requestVerifiCode,
    requestVerifi
  }
}
