import { ReactNode, useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi'; // 토글 버튼 아이콘
import { Outlet, useLocation } from 'react-router-dom';
import './MainLayout.css';

import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';

import LogoImage from '../../assets/ZconLogo2.png';
import { CustomButton } from '../../components/button/Button';
import Navigation from '../../components/navbar/Navigation';
import { useAuth } from '../../hooks/useAuth';
import { languages } from '../../i18n/option';

interface MainLayoutProps {
  children?: ReactNode

}

const MainLayout: React.FC<any> = (props) => {

  const location = useLocation()


  const { logout } = useAuth()
  const [isNavVisible, setNavVisible] = useState(true)
  const [isScreenSmall, setIsScreenSmall] = useState(false)

  useEffect(() => {
    const mediaWatcher = window.matchMedia('(max-width: 1200px)')
    mediaWatcher.addEventListener('change', resizeScreen)

    resizeScreen(mediaWatcher)

    return () => {
      mediaWatcher.removeEventListener('change', resizeScreen)
    }

    function resizeScreen(mediaWatcher: MediaQueryListEvent | MediaQueryList) {
      if (mediaWatcher.matches) {
        setIsScreenSmall(true)
        setNavVisible(false)
      } else {
        setIsScreenSmall(false)
        setNavVisible(true)
      }
    }
  }, [location])


  return (
    <div>
      <Card>
        <div className='flex justify-content-between'>
          {isScreenSmall && (<CustomButton
            icon={<FiMenu />}
            styleOption="text raised"
            onClick={() => setNavVisible(!isNavVisible)}
          />)}
          <div>
            <img
              alt='ZConverter Cloud'
              src={LogoImage}
              style={{ width: '200px', height: 'auto' }}
            />
          </div>
          <div>
            <Dropdown
              value={props.selectedLanguage}
              options={languages}
              onChange={props.onLanguageChange}
              optionLabel="name"
              className='h-full mr-2'
            />
            <CustomButton
              label='logout'
              className='p-button-sm flex-grow-1 h-full'
              onClick={logout}
            />
          </div>
        </div>
      </Card>
      <div className='flex mt-3'>
        {isNavVisible &&
          <div className=''>
            <Navigation className='w-3' />
          </div>}
        <Outlet />
        <div>{props.children}</div>
      </div>
    </div>
  )
}

export default MainLayout
