import { useEffect, useRef, useState } from "react"

import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { Toast } from "primereact/toast"
import { classNames } from "primereact/utils"
import { usePromotion } from "../../../../hooks/usePromotion"

import { CustomButton } from "../../../../components/button/Button"
import { LoadingScreen } from "../../../../components/modal/Modal.LoadingScreen"

import { CustomToast } from "../../../../components/toast/Toast"
import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from "../../../../hooks/useDayJS"
import { PromotionCodeRegistModal } from "./AddModal"
import PromotionCodeTable from "./Table"

function AdminPromotionCodePage(props: any) {
  const toast = useRef<Toast>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectedPromotionCodeList, setSelectedPromotionCodeList] = useState<any[]>([])

  const { loginCheck } = useAuth()
  const { getToday } = useDayJS()
  const { promotionCodeList, setPromotionCodeList, getPromotionCodeList, editPromotionCode, addPromotionCode, deletePromotionCode } = usePromotion()

  const deletePromotionCodes = async () => {
    try {
      let _selectedPromotionCodeList = selectedPromotionCodeList
      setIsDeleting(true)
      const deletePromises = _selectedPromotionCodeList.map((el: any) => { return deletePromotionCode({ ID: el.ID }) })

      const results = await Promise.all(deletePromises)

      let successfulResults: { [key: string]: any }[] = []
      let failedResults: { [key: string]: any }[] = []


      results.forEach((result: any, index: number) => {
        const el: any = _selectedPromotionCodeList[index]

        if (result?.state === 'success') {
          CustomToast({ toast, sev: 'success', sum: `delete promotion code ${el.Promotion_Code} success`, det: `delete success` })
          successfulResults.push(el)
        } else {
          CustomToast({ toast, sev: 'error', sum: `delete promotion code ${el.Promotion_Code} fail`, det: result?.message })
          failedResults.push(el)
        }
      })

      _selectedPromotionCodeList = successfulResults

      const remainingPromotionCodes = promotionCodeList.filter((itemA: any) => {
        return !_selectedPromotionCodeList.some((itemB: any) => {
          return itemB.ID === itemA.ID
        })
      })

      setPromotionCodeList(remainingPromotionCodes)
      setSelectedPromotionCodeList(failedResults)

    } catch (error: any) {
      console.error(error)
      CustomToast({ toast, sev: 'error', sum: 'delete promotion code fail', det: error.message, time: 5000 })
    } finally {
      setIsDeleting(false)
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await loginCheck()
          .then((result) => {
            getPromotionCodeList()
              .catch((error) => { CustomToast({ toast, sev: 'error', sum: 'Promotion Code List Request Fail', det: error?.message ?? 'error', time: 10000 }) })
          })
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])


  return (
    <>
      {isLoading &&
        <LoadingScreen
          title="Promotion Code List now Loading..."
        />
      }
      <Card className='w-full'>
        <Toast ref={toast} content={null} />
        <Card className='h-4rem'>
          <div className='flex justify-content-between flex-wrap'>
            <div className='font-bold'>Promotion Code</div>
            <div className='flex font-bold'>
              <div>{props.t('page_last_update_time')} : {getToday({})}</div>
            </div>
          </div>
        </Card>
        <Divider />
        <div className='flex justify-content-between align-content-center'>
          <div className='ml-3'>
            <p className='font-bold'>
              total : {promotionCodeList.length}
              <span className='text-blue-500'> available</span> : {promotionCodeList.filter((el: any) => el.State === 'available').length}
              <span className='text-red-500'> not available</span> : {promotionCodeList.filter((el: any) => el.State === 'not available').length}
            </p>
          </div>
          <div className='ml-3 flex'>
            <div>
              <PromotionCodeRegistModal
                promotionCodeOnlyList={Array.from(new Set<any>(promotionCodeList.map((el: any) => el.Promotion_Code)))}
                getPromotionCodeList={getPromotionCodeList}
                addPromotionCode={addPromotionCode}
              />
            </div>
            <div className='ml-3'>
              <CustomButton
                label={isDeleting ? '' : 'Delete'}
                loading={isDeleting ? true : false}
                className={classNames('p-button-danger', { 'w-6rem pl-0 pr-0': !isDeleting })}
                tooltip="Delete"
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                onClick={() => deletePromotionCodes()}
                disabled={!selectedPromotionCodeList.length || isDeleting}
              />
            </div>
          </div>
        </div>
        <Divider />
        <PromotionCodeTable
          promotionCodeList={promotionCodeList}
          editPromotionCode={editPromotionCode}
          getPromotionCodeList={getPromotionCodeList}
          selectedPromotionCodeList={selectedPromotionCodeList}
          setSelectedPromotionCodeList={setSelectedPromotionCodeList}
        />
      </Card>
    </>
  )
}

export default AdminPromotionCodePage
