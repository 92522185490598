import { useEffect, useState } from "react"
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom"
import Home from "./pages/Home"
import Login from "./pages/LogIn"
import NotFound from "./pages/NotFound"
import SignUp from "./pages/SignUp"
import MainLayout from "./pages/layout/MainLayout"

import PromotionLicenseRequestPage from "./pages/promotion"

import UserLicenseDashBoardPage from "./pages/user/license/dashboard"
import UserLicenseIssueHistoryPage from "./pages/user/license/issue.history/Issue.History"
import UserLicenseListPage from "./pages/user/license/list"
import UserLicenseRequestPage from "./pages/user/license/request/Request"
import UserPromotionDashBoardPage from "./pages/user/promotion/DashBoard"
import UserPromotionListPage from "./pages/user/promotion/List"

import AdminLicenseDashBoardPage from "./pages/admin/license/dashboard"
import AdminLicenseIssueHistoryPage from "./pages/admin/license/issue.histroy"
import AdminLicenseListPage from "./pages/admin/license/list"
import AdminLicenseRequestPage from "./pages/admin/license/request"
import AdminPromotionCodePage from "./pages/admin/promotion/codeList"
import AdminPromotionDashBoardPage from "./pages/admin/promotion/dashboard"
import AdminPromotionListPage from "./pages/admin/promotion/licenseList"

import { useAuth } from "./hooks/useAuth"
import ActivatePage from "./pages/activate"

import { useTranslation } from 'react-i18next'
import { languages } from './i18n/option'

function AuthCheck(props: any) {
  const navigate = useNavigate()
  const { loginCheck } = useAuth()

  useEffect(() => {
    const handleUnauthenticated = () => {
      sessionStorage.removeItem('id')
      navigate(window.location.pathname.startsWith('/admin') ? '/admin/login' : '/login')
    }

    const id = sessionStorage.getItem('id') ?? null
    if (!id) {
      const exceptionPathList = ['/promotion', '/activate']
      if (!exceptionPathList.includes(window.location.pathname))
        handleUnauthenticated()
    } else {
      props.setUserType(window.location.pathname.startsWith('/admin') ? 'admin' : 'user')
      if (window.location.pathname === '/' || window.location.pathname === '/admin') {
        loginCheck()
          .then((result) => { })
          .catch(handleUnauthenticated)
      }
    }
  }, [navigate, loginCheck, props.setUserType])

  return null
}

function AppRoutes() {
  const { t, i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState('kr')

  const onLanguageChange = (e: any) => {
    setSelectedLanguage(e.value)
    i18n.changeLanguage(e.value)
  }
  const [userType, setUserType] = useState('user')
  return (
    <>
      <BrowserRouter>
        <AuthCheck setUserType={setUserType} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/promotion" element={<PromotionLicenseRequestPage t={t} selectedLanguage={selectedLanguage} onLanguageChange={onLanguageChange} languages={languages} />} />
          <Route path="/activate" element={<ActivatePage t={t} selectedLanguage={selectedLanguage} onLanguageChange={onLanguageChange} languages={languages} />} />
          <Route path="v1/dashboard" element={
            <MainLayout
              selectedLanguage={selectedLanguage}
              onLanguageChange={onLanguageChange} />
          }>
            <Route index element={<NotFound />} />
            <Route path="license">
              <Route path="" element={<UserLicenseDashBoardPage userType={userType} t={t} i18n={i18n} />} />
              <Route path="list" element={<UserLicenseListPage userType={userType} t={t} i18n={i18n} />} />
              <Route path="issue/request" element={<UserLicenseRequestPage userType={userType} t={t} i18n={i18n} />} />
              <Route path="issue/history" element={<UserLicenseIssueHistoryPage userType={userType} t={t} i18n={i18n} />} />
            </Route>
            <Route path="promotion">
              <Route path="" element={<UserPromotionDashBoardPage />} />
              <Route path="list" element={<UserPromotionListPage />} />
            </Route>
          </Route>

          <Route path="/admin">
            <Route index element={<NotFound />} />
            <Route path="login" element={<Login />} />
            <Route path="dashboard" element={
              <MainLayout
                selectedLanguage={selectedLanguage}
                onLanguageChange={onLanguageChange} />
            }>
              <Route index element={<NotFound />} />
              <Route path="license">
                <Route path="" element={<AdminLicenseDashBoardPage userType={userType} t={t} i18n={i18n} />} />
                <Route path="list" element={<AdminLicenseListPage userType={userType} t={t} i18n={i18n} />} />
                <Route path="issue/request" element={<AdminLicenseRequestPage userType={userType} t={t} i18n={i18n} />} />
                <Route path="issue/history" element={<AdminLicenseIssueHistoryPage userType={userType} t={t} i18n={i18n} />} />
              </Route>
              <Route path="promotion">
                <Route path="" element={<AdminPromotionDashBoardPage userType={userType} t={t} i18n={i18n} />} />
                <Route path="list" element={<AdminPromotionListPage userType={userType} t={t} i18n={i18n} />} />
                <Route path="code" element={<AdminPromotionCodePage userType={userType} t={t} i18n={i18n} />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default AppRoutes
