import { saveAs } from 'file-saver';
import { utils, write, WorkBook } from 'xlsx';

interface ExcelHeader {
  field: string;
}

interface ExcelData {
  [key: string]: any;
}

interface ExportParams {
  data: ExcelData[];
  fileName: string;
  header?: ExcelHeader[];
}

export const useExport = () => {
  const filterExcelHeader = ({ data, header }: { data: ExcelData[]; header: ExcelHeader[] }) => {
    const _header = header.map((el) => el.field);
    return data.map(row => {
      const filteredRow: { [key: string]: any } = {};
      _header.forEach((el) => {
        filteredRow[el] = row[el];
      });
      return filteredRow;
    });
  }

  const saveAsExcelFile = (buffer: any[], fileName: string) => {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([new Uint8Array(buffer)], { type: EXCEL_TYPE });

    saveAs(data, fileName + EXCEL_EXTENSION);
  }

  const exportExcel = ({ data, fileName, header = [] }: ExportParams) => {
    const filterdData = filterExcelHeader({ data, header });
    const worksheet = utils.json_to_sheet(filterdData);
    const alignment = { vertical: "center", horizontal: "center" };
    const headerFill = { patternType: "solid", fgColor: { rgb: "FFFF00" } };

    for (const key in worksheet) {
      if (worksheet.hasOwnProperty(key) && key.startsWith('!')) {
        continue;
      }

      if (!worksheet[key].s) {
        worksheet[key].s = {};
      }
      worksheet[key].s.alignment = alignment;
    }

    const headerRowCount = 1;
    for (let i = 0; i < header.length; i++) {
      const cellAddress = utils.encode_cell({ c: i, r: 0 });
      if (!worksheet[cellAddress].s) {
        worksheet[cellAddress].s = {};
      }
      worksheet[cellAddress].s.fill = headerFill;
    }

    const workbook: WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    saveAsExcelFile(excelBuffer, fileName);
  }

  return {
    exportExcel
  }
}
