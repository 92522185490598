import { useRef, useState } from 'react'
import { Controller, FieldError, useForm } from "react-hook-form"
import './index.css'

import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'

import { usePromotion } from '../../hooks/usePromotion'

import { Dialog } from 'primereact/dialog'
import { ProgressBar } from 'primereact/progressbar'
import { CustomButton } from '../../components/button/Button'
import { CustomToast } from '../../components/toast/Toast'
import { licenseTypeList_object } from '../../constants/dropdown'

import nationCode from '../../contexts/nationCode.json'

const PromotionLicenseRequestPage = (props: any) => {
  const toast = useRef<Toast>(null)
  const promotionCodeRef = useRef<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [resultMessage, setResultMessage] = useState('')
  const [sendData, setSendData] = useState<any>(null)
  const [errMsg, setErrMsg] = useState('')

  const defaultValues = {
    LICENSE_TYPE: 1, // { "name": 'ZCM (Migration)', "value": 1 }
    PROMOTION_CODE: '',
    LICENSE_COUNT: 3,
    PORTAL_UUID: '',
    PORTAL_ID: 'user@zconverter.com',
    USER_NAME: '',
    USER_COMPANY: '',
    USER_ORGANIZATION: '',
    USER_MAIL: '',
    COMMENT: 'Promotion License Requset',
    COUNTRY: {
      "ISOAlpha2": "KO",
      "ISOAlpha3": "KOR",
      "ISONumeric": 410,
      "Continents": "Asia",
      "en": "Republic of Korea",
      "kr": "대한민국"
    },
  }

  const { requestPromotionLicense } = usePromotion()

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ defaultValues })

  const clearConfirm = () => {
    confirmDialog({
      message: 'Answers are deleted from all questions and cannot be reversed.',
      header: 'Are you sure you want to clear all entries?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        reset()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(() => {
          if (promotionCodeRef.current) {
            promotionCodeRef.current.focus()
          }
        }, 500)
      },
      content: undefined
    })
  }


  const onSubmit = async (data: any) => {
    //  프로모션 코드 검증
    data.PROMOTION_CODE = data.PROMOTION_CODE.trim()
    //  uuid 형식 검증
    data.PORTAL_UUID = data.PORTAL_UUID.trim()
    if (!(/^\{/.test(data.PORTAL_UUID))) data.PORTAL_UUID = '{' + data.PORTAL_UUID
    if (!(/\}$/.test(data.PORTAL_UUID))) data.PORTAL_UUID = data.PORTAL_UUID + '}'

    //  zem id 자동추가
    if (watch('LICENSE_TYPE') === 5 || watch('LICENSE_TYPE') === 6) { data.PORTAL_ID = 'zconverter' }

    //  Country 형태 수정
    data.COUNTRY = data.COUNTRY.ISOAlpha2

    setSendData(data)
    setIsLoading(true)
    setIsDone(false)
    await requestPromotionLicense({ data })
      .then((r: any) => {
        CustomToast({ toast, sev: 'success', sum: 'promotion license request success', det: r.message, time: 5000 })
        setResultMessage(r.message)
      })
      .catch((e) => {
        CustomToast({ toast, sev: 'error', sum: 'promotion license request fail', det: e.message, time: 5000 })
        setResultMessage('promotion license issue request fail')
        setErrMsg(e.message)
      })
      .finally(() => {
        setIsLoading(false)
        setIsDone(true)
      })
  }

  const getFormErrorMessage = (name: string) => {
    const errorMessages = errors as { [key: string]: FieldError }
    return errorMessages[name] ? <small className="p-error">{errorMessages[name].message}</small> : <small className="p-error"></small>
  }

  const header = () => {
    return (
      <div className='font-bold text-xl'>{resultMessage}</div>
    )
  }

  const onHide = () => {
    // setIsLoading(false)
    setIsDone(false)
    reset(defaultValues)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    document.body.style.overflow = 'auto' // 스타일 제거
  }

  const footer = () => {
    return (
      <div>
        <CustomButton label="cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
      </div>
    )
  }

  return (
    <>
      {isLoading && <>
        <Card className='fixed z-5 top-0 bottom-0 left-0 right-0 opacity-70 flex justify-content-center align-items-center'>
          <div>
            <div className='font-bold text-xl'>Requesting a promotion license...</div>
            <div>
              <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
            </div>
          </div>
        </Card>
      </>}

      {!isLoading && isDone && <>
        <Dialog
          content={null}
          header={header}
          visible={isDone}
          blockScroll={true}
          resizable={false}
          draggable={false}
          closeOnEscape={false}
          className='w-3'
          onHide={() => onHide()}
          footer={footer}
        >
          <Divider />
          <div>
            {resultMessage === 'Your promotion license has been issued.' &&
              <div>
                <p>
                  Successfully issued a promotion license.<br />
                  The issued license has been sent to your email, <span className='font-bold'>{sendData?.USER_MAIL}</span>.
                </p>
              </div>}
            {resultMessage === 'Your application for a promotion license has been registered.' &&
              <div>
                <p>
                  Successful request to issue a promotional license.<br />
                  Your request will be processed within a maximum of 4 hours.<br />
                  If approved, the issued license will be sent to your email <span className='font-bold'>{sendData?.USER_MAIL}</span>.
                </p>
              </div>}
            {resultMessage === 'promotion license issue request fail' &&
              <div>
                <p> {errMsg} </p>
              </div>}
          </div>
        </Dialog>
      </>}

      <ConfirmDialog content={null} />
      <form onSubmit={handleSubmit(onSubmit)} className='justify-content-center align-content-center'>
        {/* <img src={LogoImage} /> */}
        <Toast ref={toast} content={null} />

        <div className='promotionDiv'>
          <Card
            className='w-4'
            style={{ borderTop: "10px solid #0F67A8" }}>
            <div><span className='text-3xl font-medium'>ZConverter Promotion License Request</span></div>
            <Divider />
            <div className='flex flex-column align-items-start'>
              <span>contact : cloudteam@zconverter.com</span>
            </div>
            <Divider />
            <div className='flex justify-content-between align-content-center'>
              <div className='flex align-items-center'>
                <span className='text-red-500 text-xs'>{props.t('header_warning_statements')}</span>
              </div>
              <Dropdown
                value={props.selectedLanguage}
                options={props.languages}
                onChange={props.onLanguageChange}
                optionLabel="name"
              />
            </div>
          </Card>
        </div>

        <div className="promotionDiv">
          <Controller name="LICENSE_TYPE" control={control}
            rules={{ required: 'License Type is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question')} <span className='text-red-500'>*</span></span>
                <Divider />
                <Dropdown id={field.name} value={field.value}
                  onChange={(e) => field.onChange(e.value)} options={licenseTypeList_object} optionLabel="name"
                  className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('LICENSE_TYPE')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="PROMOTION_CODE" control={control}
            rules={{ required: 'Promotion Code is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question2')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field} autoFocus ref={promotionCodeRef}
                  className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('PROMOTION_CODE')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="LICENSE_COUNT" control={control}
            rules={{
              required: 'License Count is required.',
              pattern: {
                value: /^[0-9]*$/, // 숫자만 입력되도록 정규식 패턴 설정
                message: 'Only numbers are allowed',
              },
              min: { value: 1, message: 'License Quantity should be greater than or equal to 1.' },
              max: { value: 3000, message: 'License Quantity should be Less  than or equal to 3000.' }
            }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question3')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field} className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} value={field.value.toString()} />
                {getFormErrorMessage('LICENSE_COUNT')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="PORTAL_UUID" control={control}
            rules={{ required: 'Portal UUID is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question4')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field}
                  className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('PORTAL_UUID')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="PORTAL_ID" control={control}
            rules={!((watch('LICENSE_TYPE') === 5 || watch('LICENSE_TYPE') === 6)) ? { required: `ZCM, ZDM require a Portal login ID.` } : { required: '' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question5')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field} className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })}
                  value={(watch('LICENSE_TYPE') === 5 || watch('LICENSE_TYPE') === 6) ? 'ZCONVERTER' : field.value}
                  disabled={watch('LICENSE_TYPE') === 5 || watch('LICENSE_TYPE') === 6}
                />
                {getFormErrorMessage('PORTAL_ID')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="USER_NAME" control={control}
            rules={{ required: 'Name is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question6')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field} className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('USER_NAME')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="USER_COMPANY" control={control}
            rules={{ required: 'Company is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question7')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field} className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('USER_COMPANY')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="USER_MAIL" control={control}
            rules={{ required: 'Mail is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' } }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question8')} <span className='text-red-500'>*</span></span>
                <Divider />
                <InputText id={field.name} {...field} className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('USER_MAIL')}
                <Divider />
                <span>{props.t('promotion_question8_1')}</span>
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="COUNTRY" control={control}
            rules={{ required: 'COUNTRY is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question9')} <span className='text-red-500'>*</span></span>
                <Divider />
                <Dropdown id={field.name} value={field.value}
                  onChange={(e) => field.onChange(e.value)} options={nationCode} optionLabel="en"
                  filter filterBy="full" placeholder="Select a Country"
                  className={classNames('w-full', 'promotionCodeInpuTextStyle', { 'promotionCodeInpuTextErrorStyle': fieldState.invalid })} />
                {getFormErrorMessage('COUNTRY')}
              </Card>
            )} />
        </div>

        <div className="promotionDiv">
          <Controller name="COMMENT" control={control}
            rules={{ required: 'Comment is required.' }}
            render={({ field, fieldState }) => (
              <Card className={classNames('w-4', { 'promotionCardError': fieldState.invalid })}>
                <span>{props.t('promotion_question10')}</span>
                <Divider />
                <InputTextarea id={field.name} {...field} rows={5} cols={30} autoResize
                  className={classNames('w-full', { 'p-invalid': fieldState.invalid })} />
                {getFormErrorMessage('COMMENT')}
              </Card>
            )} />
        </div>

        <div className='col-12 flex justify-content-center align-items-center'>
          <div className="flex justify-content-between w-4">
            <Button onClick={clearConfirm} type="button" label="Clear" className="mt-2" size="small" style={{ backgroundColor: '#0F67A8' }} />
            <CustomButton label="Submit" className="mt-2" size="small" style={{ backgroundColor: '#0F67A8' }} />
          </div>
        </div>
      </form>
    </>
  )
}

export default PromotionLicenseRequestPage  