import axios, { AxiosResponse } from 'axios'

interface APIProps {
  path: string
  headers?: any
  body?: any
}

export const getAPI = async ({ path, headers = { 'Content-Type': 'application/json' } }: APIProps): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(path, { headers, withCredentials: true })
    return response.data
  } catch (error) { throw error }
}

export const postAPI = async ({ path, headers = { 'Content-Type': 'application/json' }, body }: APIProps): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(path, body, { headers, withCredentials: true })
    return response.data
  } catch (error) { throw error }
}

export const fileDownloadAPI = async ({ path, headers = { 'Content-Type': 'application/json' }, body }: APIProps): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(path, body, { headers, withCredentials: true, responseType: 'blob' })
    return response.data
  } catch (error) { throw error }
}

export const deleteAPI = async ({ path, headers = { 'Content-Type': 'application/json' }, body }: APIProps): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(path, { headers, withCredentials: true, data: body })
    return response.data
  } catch (error) { throw error }
}

export const putAPI = async ({ path, headers = { 'Content-Type': 'application/json' }, body }: APIProps): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(path, body, { headers, withCredentials: true })
    return response.data
  } catch (error) { throw error }
}
