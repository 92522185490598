import { Accordion, AccordionTab } from 'primereact/accordion'
import { Calendar } from 'primereact/calendar'
import { Card } from 'primereact/card'
import { Checkbox } from 'primereact/checkbox'
import { Divider } from 'primereact/divider'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Toast } from 'primereact/toast'
import { Nullable } from 'primereact/ts-helpers'
import { useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { CustomButton } from '../../../../components/button/Button'
import PieChart from "../../../../components/chart/pie"
import { CustomToast } from '../../../../components/toast/Toast'
import { licenseTypeList } from '../../../../constants/table'
import { useAuth } from "../../../../hooks/useAuth"
import { useDayJS } from "../../../../hooks/useDayJS"
import { useLicense } from "../../../../hooks/useLicense"
import './index.css'

function AdminLicenseDashBoardPage(props: any) {
  const toast = useRef<Toast>(null)
  const op2 = useRef<OverlayPanel>(null)
  const navigate = useNavigate()
  const { getToday, getDate, exChangeFormat } = useDayJS()
  const { loginCheck } = useAuth()
  const { licenseList,
    licenseApprovedToday,
    licenseExpiringToday,
    licenseIssueRequestList,
    licenseCategorizationList,
    licenseCategorizationByLicenseTypeList,
    getLicenseList,
    getLicenseIssueRequestList,
    setLicenseDashBoardDatePeriod,
  } = useLicense()

  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null)
  const [datesType, setDatesType] = useState<string>('')
  const [now, setNow] = useState(getToday({}))

  useEffect(() => {
    loginCheck()
      .then((result: any) => {
        if (result.state === 'fail') { throw new Error(result.message) }
        setNow(getToday({}))
        getLicenseIssueRequestList()
          .catch((error: any) => { CustomToast({ toast, sev: 'error', sum: 'Failed to get license request list.', det: `${error.message}`, time: 5000 }) })
        getLicenseList()
          .catch((error: any) => { CustomToast({ toast, sev: 'error', sum: 'Failed to get license list.', det: `${error.message}`, time: 5000 }) })
      })
      .catch((error: any) => {/** 로그인 관련 에러는 이미 다 처리되어 있음 */ })

    return () => { }
  }, [])

  const summaryData = [
    { title: 'All Licenses', count: licenseList.length, link: '/admin/dashboard/license/list' },
    { title: 'Pending Approve', count: licenseIssueRequestList.length, link: '/admin/dashboard/license/issue/request' },
    { title: 'Expire Today', count: licenseExpiringToday.length, link: '/admin/dashboard/license/list' },
    { title: 'Approved Today', count: licenseApprovedToday.length, link: '/admin/dashboard/license/issue/history' },
  ]

  const toggleDataTable = (event: any) => { op2.current?.toggle(event) }
  const onDateChange = (type: any) => {
    setDatesType(type)
    setDates([getDate({ date: now, type }).toDate(), new Date])
  }

  return (
    <>
      <Toast ref={toast} content={null} />
      <Card className='w-full'>
        <div className='grid'>
          <div className='col-12'>
            <Card>
              <div className='flex justify-content-between'>
                <span className='font-bold'>License DashBoard</span>
                <span className=''>마지막 갱신 : {now}</span>
              </div>
            </Card>
          </div>

          {/* 최상단 전체 라이센스 요약 */}
          {summaryData.map((data, index) => (
            <div className='col-12 md:col-6 lg:col-6 xl:col-3' key={index}>
              <Card className='mb-0'>
                <span className="block text-left text-500 font-medium mb-2">{data.title}</span>
                <Divider className='mt-0' />
                <div className='flex justify-content-between mb-3'>
                  <div className="text-900 font-medium text-xl">{data.count} EA</div>
                  <div className="flex align-items-center justify-content-center summaryDataLink">
                    <i className="pi pi-angle-double-right text-xl" onClick={() => { navigate(data.link) }} />
                  </div>
                </div>
                <span className="text-green-500 font-medium"></span>
                <span className="text-500"></span>
              </Card>
            </div>
          ))}
          <Divider />
          {/* 라이센스 기간별 요약 설정 */}
          <div className='col-12'>
            <Card>
              <div className='flex justify-content-between'>
                <div className="flex align-items-center">
                  <span className='font-bold'>Period : {dates === null ? 'All' : `${exChangeFormat({ date: dates![0], format: 'YYYY-MM-DD' })} ~ ${exChangeFormat({ date: dates![1], format: 'YYYY-MM-DD' })}`} ( total : {licenseCategorizationByLicenseTypeList.reduce((acc, cur) => acc + cur.value, 0)} )</span>
                </div>
                <div><CustomButton type="button" icon="pi pi-cog" onClick={toggleDataTable} styleOption="text rounded" /></div>
              </div>
            </Card>
            <OverlayPanel ref={op2} appendTo={typeof window !== 'undefined' ? document.body : null} showCloseIcon id="overlay_panel" style={{ width: '330px' }}>
              <div className='grid'>
                <div className='col-12'>기간설정</div>
                <div className="col-12 flex flex-wrap justify-content-center">
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient1" name="1 year" value="beforOneYear" onChange={(e) => onDateChange(e.value)} checked={datesType === 'beforOneYear'} />
                    <label htmlFor="ingredient1" className="mx-1">1 year</label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient2" name="1 month" value="beforOneMonth" onChange={(e) => onDateChange(e.value)} checked={datesType === 'beforOneMonth'} />
                    <label htmlFor="ingredient2" className="mx-1">1 month</label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient3" name="1 week" value="beforOneWeek" onChange={(e) => onDateChange(e.value)} checked={datesType === 'beforOneWeek'} />
                    <label htmlFor="ingredient3" className="mx-1">1 week</label>
                  </div>
                  <div className="flex align-items-center">
                    <Checkbox inputId="ingredient4" name="today" value="today" onChange={(e) => onDateChange(e.value)} checked={datesType === 'today'} />
                    <label htmlFor="ingredient4" className="ml-1">today</label>
                  </div>
                </div>
              </div>
              <Divider />
              <div>
                <Calendar className='w-full' inputClassName="text-center" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput dateFormat="yy/mm/dd" />
                <Divider />
                <div className='flex justify-content-between'>
                  <CustomButton className="h-1rem" type="button" label="apply" onClick={(e: any) => { setLicenseDashBoardDatePeriod(dates); toggleDataTable(e) }} />
                  <CustomButton className="h-1rem" type="button" label="clear" onClick={() => { setDates(null); setDatesType('') }} />
                </div>
              </div>
            </OverlayPanel>
          </div>
          {/* 라이센스 기간별 요약 출력 */}
          <div className='col-12'>
            <Accordion activeIndex={0}>
              <AccordionTab header="라이센스 요약" headerClassName='text-left'>
                <div className='grid'>
                  <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="License issuance configuration"
                      seriesName="License"
                      legendData={licenseTypeList}
                      mainData={licenseCategorizationList}
                    />
                  </div>
                  <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6'>
                    <PieChart
                      title="License issuance type"
                      seriesName="License"
                      legendData={['ZCM', 'ZDM', 'ZEM']}
                      mainData={licenseCategorizationByLicenseTypeList}
                    />
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
            <Divider />
          </div>
        </div>
      </Card>
    </>
  )
}

export default AdminLicenseDashBoardPage
