import dayjs from 'dayjs'

import { Accordion, AccordionTab } from 'primereact/accordion'

import { useDayJS } from '../../../../hooks/useDayJS'
import { classNames } from 'primereact/utils'
import ZEMLicenseHistoryTable from './Table.ZEMLicenseHistory'

export const LicenseHistoryAccordion = (props: {
  selectedLicense: ILicense,
  licenseHistory: any[]
}) => {

  const { getToday } = useDayJS()

  return (
    <>
      <Accordion activeIndex={0} className='mt-3'>
        <AccordionTab header={
          <span className='flex justify-contents-left'>License [ {props.selectedLicense.License_Name} ]'s History
            {dayjs(props.selectedLicense.License_Expiration_Date).isBefore(getToday({})) && <span className={classNames({ 'ml-2 font-bold text-red-500': dayjs(props.selectedLicense.License_Expiration_Date).isBefore(getToday({})) })}>(Expired!)</span>}
          </span>}>
          <div>
            {props.selectedLicense.License_Version === 'ZEM' &&
              <ZEMLicenseHistoryTable
                selectedLicense={props.selectedLicense}
                historyList={props.licenseHistory}
              />}

          </div>
        </AccordionTab>
      </Accordion>
    </>
  )
}

export default LicenseHistoryAccordion