import { useRef, useState } from "react"
import { Controller, FieldError, useForm } from "react-hook-form"

import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { FileUpload, FileUploadBeforeUploadEvent, FileUploadErrorEvent, FileUploadUploadEvent } from "primereact/fileupload"
import { InputText } from "primereact/inputtext"
import { Toast } from "primereact/toast"

import { CustomButton } from "../../components/button/Button"
import { CustomToast } from "../../components/toast/Toast"

import { classNames } from "primereact/utils"
import { URLLIST } from "../../constants/url"
import { useAuth } from "../../hooks/useAuth"
import { postAPI } from "../../services/api"

import { Dropdown } from "primereact/dropdown"
import LogoImage from '../../assets/ZconLogo2.png'

const ActivatePage = (props: any) => {

  const { requestVerifiCode, requestVerifi } = useAuth()
  const defaultValues = { mail: "", code: "", sendMail: "" }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ defaultValues })
  const toast = useRef<Toast>(null)
  const [btn1, setBtn1] = useState<boolean>(false)
  const [btn2, setBtn2] = useState<boolean>(false)
  const [btn3, setBtn3] = useState<boolean>(false)
  const [loading, setloading] = useState<boolean>(false)
  const [uploadFileName, setuploadFileName] = useState<string>('')
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false)
  const [showFileUpload, setshowFileUpload] = useState<boolean>(false)
  const [serverResponse, setServerResponse] = useState<any>(null)
  const [uploadFilePath, setUploadFilePath] = useState<string>('')

  const init = () => {
    setBtn1(false)
    setBtn2(false)
    setBtn3(false)
    setuploadFileName('')
    setShowCodeInput(false)
    setshowFileUpload(false)
    setServerResponse(null)
    reset()
  }

  const getFormErrorMessage = (name: string) => {
    const errorMessages = errors as { [key: string]: FieldError }
    return errorMessages[name] ? <small className="p-error">{errorMessages[name].message}</small> : <small className="p-error"></small>
  }

  const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {
    e.formData.append('mail', watch('mail'))
  }

  //  업로드 실패로 에러 발생시
  const onError = (e: FileUploadErrorEvent) => {
    const response = JSON.parse(e.xhr.response)
    CustomToast({ toast, sev: 'error', sum: 'file upload fail.', det: `${response.message ?? ''}`, time: 10000 })
  }

  const onUpload = (e: FileUploadUploadEvent) => {
    //  서버 응답을 JSON 형태로 파싱
    const response = JSON.parse(e.xhr.response)
    //  서버 응답을 상태 변수에 저장
    setServerResponse(response)
    setUploadFilePath(response.data.path ?? '')

    //  파일 정보 파싱
    const { files } = e
    const filesInfo = files.map((file) => ({ name: file.name, size: file.size, type: file.type, }))
    let fileName = `${filesInfo[0].name.split('.zconli')[0]}_result.zconli`
    setuploadFileName(fileName)
  }

  //  인증코드 요청
  const onClickRequestVerifiCode = async (data: any) => {
    try {
      setloading(true)
      await requestVerifiCode({ mail: data.mail })
        .then((res: any) => {
          if (res.state === 'success') {
            CustomToast({ toast, sev: 'success', sum: 'verify code request success.', det: `A verification email has been sent to ${data.mail}.`, time: 10000 })
            setShowCodeInput(true)
            setBtn1(true)
          }
          else { throw new Error(res.message) }
        })
        .catch((error: any) => {
          CustomToast({ toast, sev: 'error', sum: 'verify code request fail.', det: error.response?.data?.message ?? 'verify code request fail.', time: 10000 })
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'verify code request fail.', det: error.message ?? 'verify code request fail.', time: 10000 })
    } finally {
      setloading(false)
    }
  }

  //  인증코드 - 인증요청
  const onClickVerifiMail = async (data: any) => {
    try {
      setloading(true)
      await requestVerifi({ mail: data.mail, code: data.code })
        .then((res: any) => {
          if (res.state === 'success') {
            CustomToast({ toast, sev: 'success', sum: 'verify code request success.', det: `A verification email has been sent to ${data.mail}.`, time: 10000 })
            setshowFileUpload(true)
            setBtn2(true)
          }
          else { throw new Error(res.message) }
        })
        .catch((error: any) => {
          CustomToast({ toast, sev: 'error', sum: 'verify code request fail.', det: error.response?.data?.message ?? 'verify code request fail.', time: 10000 })
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'verify code request fail.', det: error.message ?? 'verify code request fail.', time: 10000 })
    } finally {
      setloading(false)
    }
  }

  //  결과 파일 메일 전송 요청
  const onClickSendResultToMail = async (data: any) => {
    setBtn3(true)
    try {
      const path = URLLIST['ACTIVATION_RESULT_SEND_TO_MAIL']
      await postAPI({ path, body: { mail: watch('sendMail'), path: uploadFilePath } })
        .then((result) => { return result })
        .catch((error) => { throw error })
        .then((res: any) => {
          if (res.state === 'success') {
            CustomToast({ toast, sev: 'success', sum: 'mail send success.', det: `A result email has been sent to ${data.sendMail}.`, time: 10000 })
            setshowFileUpload(true)
            setBtn2(true)
          }
          else { throw new Error(res.message) }
        })
        .catch((error: any) => {
          CustomToast({ toast, sev: 'error', sum: 'mail send fail.', det: error.response?.data?.message ?? 'result email send fail.', time: 10000 })
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'mail send fail.', det: error.message ?? 'result email send fail.', time: 10000 })
    } finally {
      setBtn3(false)
    }
  }

  //  결과 파일로 다운로드
  const downloadToFile = () => {
    if (serverResponse) {
      const jsonString = serverResponse.data.data.replace(/\\n/g, '\n')

      const blob = new Blob([jsonString], { type: 'text/plain' })

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)

      downloadLink.download = uploadFileName

      document.body.appendChild(downloadLink)
      downloadLink.click()

      document.body.removeChild(downloadLink)
    }

  }

  return (
    <>
      <Toast ref={toast} content={null} />
      <Card
        style={{ borderTop: "10px solid #0F67A8" }}>
        <div className="grid"> {/* Added justify-center class */}
          <div className="xl:col-4 col-12 flex justify-content-start">
            <img
              alt='ZConverter Cloud'
              src={LogoImage}
              style={{ width: '200px', height: 'auto' }}
            />
            <Dropdown
              value={props.selectedLanguage}
              options={props.languages}
              onChange={props.onLanguageChange}
              placeholder="Select a Language" className="w-6rem"
              optionLabel="name"
            />
          </div>
          <div className="xl:col-4 col-12">
            <span className='text-3xl font-medium'>ZConverter License Activate</span>
          </div>
          <div className="xl:col-4 col-12 flex justify-content-end">

          </div>
        </div>
        <Divider />
        <Card>
          <p className="text-left">
            {props.t("header")}<br />
            {/* ZConverter Activator 페이지는 ZConverter 솔루션 사용을 위한 인증 서비스를 제공합니다.<br /> */}
            {props.t("header_1")}<br /><br />
            {/* 인증 과정은 세 가지 주요 단계로 구성됩니다.<br /><br /> */}
            <span className="font-bold">{props.t("header_step1_title")}</span> : {props.t("header_step1_contents")}<br />
            <span className="font-bold">{props.t("header_step2_title")}</span> : {props.t("header_step2_contents")}<br />
            <span className="font-bold">{props.t("header_step3_title")}</span> : {props.t("header_step3_contents")}<br />
            {/* <br /><br />
            <span>contact : cloudteam@zconverter.com</span> */}
          </p>
        </Card>
        <Divider />
        <Card>
          <Divider align="left" className="mt-0">
            <div className="inline-flex align-items-center">
              <i className="pi pi-caret-right mr-2"></i>
              <b>{props.t("step1_title")}</b>
            </div>
          </Divider>
          <div className="text-left">
            <p className="text-sm">
              {props.t("step1_contents")}
            </p>
          </div>
          <div>
            <Divider />
            <form onSubmit={handleSubmit(onClickRequestVerifiCode)}>
              <div className="flex justify-content-start">
                <div className={classNames({ 'mt-2': watch("mail") })}>
                  <Controller
                    name="mail"
                    control={control}
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className={classNames({ "p-error": errors[field.name] })}></label>
                        <span className="p-float-label">
                          <InputText
                            id={field.name}
                            value={field.value}
                            className={classNames("w-full", {
                              "p-invalid": fieldState.error,
                            })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                          <label htmlFor={field.name}>E-mail</label>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
                <div className="ml-2">
                  <CustomButton
                    label={props.t("step1_request_authorization_code_btn")}
                    type="submit"
                    className="w-10rem"
                    disabled={loading || btn1}
                  />
                </div>
              </div>
            </form>
            {showCodeInput &&
              <div>
                <Divider />
                <form onSubmit={handleSubmit(onClickVerifiMail)}>
                  <div className="flex justify-content-start">
                    <div className={classNames({ 'mt-2': watch("code") })}>
                      <Controller
                        name="code"
                        control={control}
                        rules={{
                          required: "Code is required.",
                          pattern: {
                            value: /^.{8}$/,
                            message: "Invalid Code.",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label htmlFor={field.name} className={classNames({ "p-error": errors[field.name] })}></label>
                            <span className="p-float-label">
                              <InputText
                                id={field.name}
                                value={field.value}
                                className={classNames("w-full", {
                                  "p-invalid": fieldState.error,
                                })}
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                              <label htmlFor={field.name}>Code</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="ml-2">
                      <CustomButton
                        label={props.t("step1_mail_verification_btn")}
                        type="submit"
                        className="w-10rem"
                        disabled={loading || btn2}
                      />
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
        </Card>
        {showFileUpload &&
          <>
            <Divider />
            <Card>
              <Divider align="left" className="mt-0">
                <div className="inline-flex align-items-center">
                  <i className="pi pi-caret-right mr-2"></i>
                  <b>{props.t("step2_title")}</b>
                </div>
              </Divider>
              <div className="text-left">
                <p>
                  {props.t("step2_contents")}
                </p>
              </div>

              <Divider />
              <div className="card">
                <FileUpload
                  name="licenseActivateFile"
                  url={'https://www.ziacloud.net:10001/v1/activate/upload'}
                  accept=".zconli"
                  maxFileSize={10000000}
                  emptyTemplate={<p className="m-0 text-blue-500">Drag and drop file to here to upload.</p>}
                  onUpload={onUpload}
                  onBeforeUpload={onBeforeUpload}
                  onError={onError}
                  contentClassName="text-left"
                />
                <Divider />
              </div>
            </Card></>
        }

        {serverResponse &&
          <>
            <Divider />
            <Card>
              <Divider align="left" className="mt-0">
                <div className="inline-flex align-items-center">
                  <i className="pi pi-caret-right mr-2"></i>
                  <b>{props.t("step3_title")}</b>
                </div>
              </Divider>
              <div className="text-left">
                <p>
                  {props.t("step3_contents")}
                </p>
              </div>
              <Divider />
              <div className="flex">
                <form onSubmit={handleSubmit(onClickSendResultToMail)}>
                  <div className="flex justify-content-between">
                    <div className={classNames({ 'mt-2': watch("sendMail") })}>
                      <Controller
                        name="sendMail"
                        control={control}
                        rules={{
                          required: "Email is required.",
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address.",
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <label htmlFor={field.name} className={classNames({ "p-error": errors[field.name] })}></label>
                            <span className="p-float-label">
                              <InputText
                                id={field.name}
                                value={field.value}
                                className={classNames("w-full", {
                                  "p-invalid": fieldState.error,
                                })}
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                              <label htmlFor={field.name}>Send Mail</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="ml-2">
                      <CustomButton
                        label={props.t("step3_send_mail_btn")}
                        type="submit"
                        className="w-10rem"
                        disabled={btn3}
                      />
                      <CustomButton
                        label={props.t("step3_file_download_btn")}
                        className="w-10rem ml-2"
                        onClick={downloadToFile} />
                    </div>
                  </div>
                </form>
              </div>
            </Card></>
        }
      </Card>
      <Card className="mt-2" style={{ position: 'fixed', bottom: '0', left: '0', width: '100%' }}>
        <p>Copyright © <a className="text-blue-400" href="https://www.zconverter.com/">ZConverter Cloud</a> All rights reserved.</p>
      </Card>

    </>
  )

}

export default ActivatePage