import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { ConfirmDialog } from "primereact/confirmdialog"
import { DataTable } from "primereact/datatable"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from "primereact/toast"
import { useRef, useState } from "react"
import { CustomButton } from "../../../../components/button/Button"
import { CustomToast } from "../../../../components/toast/Toast"
import { defaultSelectedPromotionCodeColumn, licenseTypeList, licenseTypeObject, promotionCodeColumn } from "../../../../constants/table"
import { PromotionCodeEditModal } from "./EditModal"

type TempRowDataType = {
  [key: string]: string | number;
};


function PromotionCodeTable(props: any) {
  const columns = promotionCodeColumn
  const toast = useRef<Toast>(null)
  //  간단하게 promotion code state 변경 관련
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [tempState, setTempState] = useState(null)
  const [tempRowData, setTempRowData] = useState(null)
  //
  const [selectedColumns, setSelectedColumns] = useState(columns.filter((el) => defaultSelectedPromotionCodeColumn.includes(el.field)))
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    License_Type: { value: null, matchMode: FilterMatchMode.IN },
    State: { value: null, matchMode: FilterMatchMode.IN },
  })

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      License_Type: { value: null, matchMode: FilterMatchMode.IN },
      State: { value: null, matchMode: FilterMatchMode.IN },
    })
    setGlobalFilterValue('')
    setSelectedColumns(columns.filter((el) => defaultSelectedPromotionCodeColumn.includes(el.field)))
  }

  const onColumnToggle = (event: any) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field))
    setSelectedColumns(orderedSelectedColumns)
  }

  const changeStateOnly = (value: any, rowData: any) => {
    setTempState(value)
    setTempRowData(rowData)
    setDisplayConfirmation(true)
  }

  const renderHeader = () => {
    return (
      <div className="flex flex-warp justify-content-between">
        <div style={{ textAlign: 'left', flex: '1 0 auto' }}>
        </div>
        <div className='flex flex-wrap flex-grow'>
          <div className='mr-3'>
            <CustomButton
              label="Filter Clear"
              styleOption="outlined"
              onClick={initFilter}
            />
          </div>
          <div className='flex flex-wrap'>
            <div className='mr-3' style={{ textAlign: 'left', flex: '1 1 auto' }}>
              <MultiSelect maxSelectedLabels={4} value={selectedColumns} options={columns} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
            </div>
            <div className='flex' style={{ flex: '1 1 auto', flexDirection: 'column', flexWrap: 'wrap' }}>
              <span className="p-input-icon-left" style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-search" style={{ marginRight: '0.5em' }} />
                <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder="Keyword Search" />
              </span>
            </div>
          </div>
        </div>
      </div >
    )
  }

  const rowSelect = (value: any) => { props.setSelectedPromotionCodeList(value) }

  const licenseTypeColumn = (rowData: any) => {
    return <span>{licenseTypeObject[`${rowData.License_Type}`]}</span>
  }

  const licenseTypeFilterBody = (options: any) => {
    // return <span className={classNames('font-bold', (options === 'Allow') ? 'text-blue-500' : 'text-red-500')}>{options}</span>
    return <span >{options}</span>
  }

  const requestStateFilterBody = (options: any) => {
    // return <span className={classNames('font-bold', (options === 'Allow') ? 'text-blue-500' : 'text-red-500')}>{options}</span>
    return <span >{options}</span>
  }

  const licenseTypeFilter = (options: any) => {
    return <MultiSelect value={options.value} options={licenseTypeList} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={licenseTypeFilterBody} placeholder="Select License Type" className="p-column-filter" />
  }

  const stateFilter = (options: any) => {
    return <MultiSelect value={options.value} options={['available', 'not available']} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={requestStateFilterBody} placeholder="Select a Status" className="p-column-filter" />
  }

  const commentBody = (rowData: any) => {
    const comment = rowData.Comment ?? ''
    return <>
      {/* <Tooltip target=".commnet" mouseTrack mouseTrackLeft={10} /> */}
      <div className='commnet white-space-nowrap overflow-hidden text-overflow-ellipsis' data-pr-tooltip={comment} data-pr-showdelay={5} style={{ width: "200px", backgroundColor: 'transparent' }}>{comment}</div>
    </>
  }

  const stateBody = (rowData: any) => {
    const textColor = rowData.State === 'available' ? 'text-blue-500' : 'text-red-500'
    return <span className={`font-bold ${textColor}`}>{rowData.State}</span>
  }

  const accept = async () => {
    try {
      const _data = Object.keys(tempRowData!).reduce((result: any, key: string) => {
        let _key = key.toUpperCase()

        if (_key === 'STATE') {
          if (tempState) result[_key] = 'available'
          else result[_key] = 'not available'
        } else {
          if (_key === 'TOTAL_QUANTITY') { _key = 'LICENSE_COUNT' }
          if (_key === 'CODE_EXPIRE_DATE') { _key = 'EXPIRE_DATE' }
          if (_key === 'CODE_REGIST_DATE') { _key = 'REGIST_DATE' }
          result[_key] = tempRowData![key]
          if (_key === 'LICENSE_TYPE') {
            // console.log(`_key : ${_key}`)
            // console.log(`result[_key] : ${result[_key]}`)
            result[_key] = (licenseTypeList.indexOf(licenseTypeObject[result[_key]]) + 1)
          }
        }

        return result
      }, {} as TempRowDataType)

      // console.log(_data)

      await props.editPromotionCode({ data: _data })
        .then((r: any) => {
          if (r?.state === 'success') {
            CustomToast({ toast, sev: 'success', sum: 'promotion code edit success', det: r.message, time: 5000 })
            props.getPromotionCodeList()
          }
          else {
            CustomToast({ toast, sev: 'error', sum: 'promotion code edit fail', det: r?.message, time: 5000 })
          }
        })
        .catch((e: any) => {
          CustomToast({ toast, sev: 'error', sum: 'promotion code edit fail', det: e.message, time: 5000 })
        })

      setDisplayConfirmation(false)
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'promotion code edit fail', det: error.message, time: 5000 })
    }
  }

  const stateChangeBody = (rowData: any) => {
    return (
      <>
        <ConfirmDialog visible={displayConfirmation} content={null}
          onHide={() => setDisplayConfirmation(false)}
          message="Are you sure you want to change the state?"
          header="Confirmation" icon="pi pi-exclamation-triangle"
          accept={() => accept()}
          reject={() => setDisplayConfirmation(false)} />
        <div onClick={(e) => e.stopPropagation()}>
          <InputSwitch checked={rowData.State === 'available' ? true : false} onChange={(e) => changeStateOnly(e.value, rowData)} />
        </div>
      </>

    )
  }

  const editBody = (rowData: any) => {
    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <PromotionCodeEditModal
            rowData={rowData}
            getPromotionCodeList={props.getPromotionCodeList}
            editPromotionCode={props.editPromotionCode}
          />
        </div></>
    )
  }

  return (
    <div className="card">
      <Toast ref={toast} content={null} />
      <DataTable
        //  테이블 표기 데이터 | 테이블 헤더 | 테이블 스타일 추가
        value={props.promotionCodeList} header={() => renderHeader()} tableStyle={{ minWidth: '50rem' }}
        //  글로벌 필터
        globalFilterFields={selectedColumns.map((el) => el.field)}
        //  테이블 크기 | 테이블 줄무늬 추가 | 컬럼 정렬 추가
        size='small' stripedRows removableSort
        //  검색 필터 옵션 | 데이터 없을시 보여주는 메시지 | 컬럼 헤더 위치이동 가능
        filters={filters} emptyMessage="No Promotion Code found." reorderableColumns
        //  페이지 네이션
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
        //  table 컬럼 선택기능 추가 ( 단일 선택 )
        selection={props.selectedPromotionCodeList} selectionPageOnly onSelectionChange={e => rowSelect(e.value)}
        //  필터 데이터 변경시 동작
        onValueChange={filteredValues => setFilteredValue(filteredValues)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
        {selectedColumns.map((col) => {
          if (col.field === 'License_Type') {
            return <Column key={col.field} field={col.field} filterField={col.field}
              header={col.header} body={licenseTypeColumn} headerStyle={{ minWidth: '150px' }} bodyStyle={{ minWidth: '150px' }}
              filter filterElement={licenseTypeFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
            />
          }
          if (col.field === 'State') {
            return <Column key={col.field} field={col.field} filterField={col.field}
              header={col.header} body={stateBody} headerStyle={{ minWidth: '120px' }} bodyStyle={{ minWidth: '120px' }}
              filter filterElement={stateFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
            />
          }
          if (col.field === 'Comment') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} body={commentBody}
            />
          }
          return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} sortable />
        })}
        <Column headerStyle={{ width: '4rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={stateChangeBody} />
        <Column headerStyle={{ width: '4rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={editBody} />
      </DataTable>
    </div>
  )
}
export default PromotionCodeTable