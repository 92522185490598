import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { Divider } from "primereact/divider"
import { Toast } from "primereact/toast"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"
import { CustomButton } from "../../../../components/button/Button"
import { CustomToast } from "../../../../components/toast/Toast"
import { licenseTypeList, licenseTypeObject } from "../../../../constants/table"

function PromotionLicenseDecideModal(props: any) {
  const toast = useRef<Toast>(null)
  const [openType, setOpenType] = useState<string>('')
  const [requestLicenseModal, setRequestLicenseModal] = useState(false)
  const [selectedPromotionRequestList, setSelectedPromotionRequestList] = useState<any>(props.selectedPromotionLicenseList ?? [])
  const [isDeleting, setIsDeleting] = useState(false)

  const openModal = async (type: string) => {
    setOpenType(type)
    setRequestLicenseModal(true)
    setSelectedPromotionRequestList(props.selectedPromotionLicenseList)
  }

  const onHide = () => { setRequestLicenseModal(false) }

  useEffect(() => {
    if (props?.selectedPromotionLicenseList) {
      setSelectedPromotionRequestList(props.selectedPromotionLicenseList)
    }

    return () => { }
  }, [props])


  //  라이센스 만료 날자 수정
  const onDateChange = (e: any, chData: any) => {
    selectedPromotionRequestList.filter((el: any) => {
      if (el.ID === e.ID) { el.License_Expire_Date = chData }
      return el
    })
  }

  //  프로모션 라이센스 승인
  const approve = async () => {
    try {
      props.loginCheck()
        .then(async (result: any) => {
          if (result.state === 'fail') { throw new Error(result.message) }
          let t = [...selectedPromotionRequestList]
          for (let el of t) {
            el.License_Type = (licenseTypeList.indexOf(el.License_Type) + 1)
            const r = await props.decidePromotionLicenseRequest({ data: el, state: 'approve' })
            if (r.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: r.message ?? '', det: `Name : ${el.License_Name}` })
              t = t.filter((item) => item.ID !== el.ID)
            } else {
              CustomToast({ toast, sev: 'error', sum: 'License Issue approve Fail', det: `Name : ${el.License_Name}`, time: 5000 })
            }
          }
          return t
        })
        .catch((error: any) => {/** 로그인 관련 에러는 이미 다 처리되어 있음 */ })
        .then((t: any) => {
          setSelectedPromotionRequestList(t)
          if (!t.length) { onHide() }
          props.getPromotionLicenseRequsetList()
          props.setselectedPromotionLicenseList([])
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'License Issue approve Fail', det: error.message, time: 5000 })
    }
  }

  //  프로모션 라이센스 거부
  const decline = async () => {
    try {
      props.loginCheck()
        .then(async (result: any) => {
          if (result.state === 'fail') { throw new Error(result.message) }
          let t = [...selectedPromotionRequestList]
          for (let el of t) {
            el.License_Type = (licenseTypeList.indexOf(el.License_Type) + 1)
            const r = await props.decidePromotionLicenseRequest({ data: el, state: 'deny' })
            if (r.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: r.message ?? '', det: `Name : ${el.License_Name}` })
              t = t.filter((item) => item.ID !== el.ID)
            } else {
              CustomToast({ toast, sev: 'error', sum: 'License Issue Decline Fail', det: `Name : ${el.License_Name}`, time: 5000 })
            }
          }
          return t
        })
        .catch((error: any) => {/** 로그인 관련 에러는 이미 다 처리되어 있음 */ })
        .then((t: any) => {
          setSelectedPromotionRequestList(t)
          if (!t.length) { onHide() }
          props.getPromotionLicenseRequsetList()
          props.setselectedPromotionLicenseList([])
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'License Issue Decline Fail', det: error.message, time: 5000 })
    }
  }

  const removeItemToSelected = (el: any) => {
    const updatedData = props.selectedPromotionLicenseList.filter((item: any) => item.ID !== el.ID)
    props.setselectedPromotionLicenseList(updatedData)
    setSelectedPromotionRequestList(selectedPromotionRequestList.filter((item: any) => item.ID !== el.ID))
  }

  //  프로모션 라이센스, 프로모션 라이센스 요청 삭제
  const deletePromotionLicenseRequest = async () => {
    try {
      setIsDeleting(true)
      await props.loginCheck()
        .then(async (result: any) => {
          let t = [...selectedPromotionRequestList ?? props.selectedPromotionLicenseList]
          for (let el of t) {
            const r = await props.deletePromotionLicense({ data: el })
            if (r.state === 'success') {
              CustomToast({ toast, sev: 'success', sum: 'promotion license(request) delete success', det: `Name : ${el.License_Name}` })
              t = t.filter((item) => item.ID !== el.ID)
            } else {
              CustomToast({ toast, sev: 'error', sum: 'promotion license(request) delete fail', det: `Name : ${el.License_Name}`, time: 5000 })
            }
          }
          return t
        })
        .catch((error: any) => {/** 로그인 관련 에러는 이미 다 처리되어 있음 */ })
        .then((t: any) => {
          props.setselectedPromotionLicenseList(t)
          props.getPromotionLicenseRequsetList()
          setIsDeleting(false)
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'promotion license delete fail', det: error.message, time: 5000 })
    }
  }

  const header = () => {
    return (
      <>
        {openType === 'issue' && <>
          <div>Promotion License Request Approve List</div>
          <div>{props.selectedPromotionLicenseList.length} items selected.</div>
        </>}
        {openType === 'decline' && <>
          <div>Promotion License Request Decline List</div>
          <div className='mt-3'>{props.selectedPromotionLicenseList.length} items selected.</div>
        </>}
      </>
    )
  }

  const footer = () => {
    return (
      <div>
        <CustomButton label="cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
        {openType === 'issue' && <CustomButton label="Approve All" icon="pi pi-check" onClick={() => approve()} autoFocus disabled={!props.selectedPromotionLicenseList.length} />}
        {openType === 'decline' && <CustomButton label="Decline All" icon="pi pi-times-circle" onClick={() => decline()} autoFocus disabled={!props.selectedPromotionLicenseList.length} />}
      </div>
    )
  }

  return (
    <>
      <Toast ref={toast} content={null} />
      <div className='flex'>
        <div>
          <CustomButton
            label='Approve'
            className='p-button-success w-6rem pl-0 pr-0'
            tooltip="Approve"
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => openModal('issue')}
            disabled={!props.selectedPromotionLicenseList.length || !props.selectedPromotionLicenseList.every((el: any) => el.State === 'waiting')}
          />
        </div>
        <div className='ml-3'>
          <CustomButton
            label='Deny'
            className='p-button-warning w-6rem pl-0 pr-0'
            tooltip="Deny"
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => openModal('decline')}
            disabled={!props.selectedPromotionLicenseList.length || !props.selectedPromotionLicenseList.every((el: any) => el.State === 'waiting')}
          />
        </div>
        <div className='ml-3'>
          <CustomButton
            label={isDeleting ? '' : 'Delete'}
            loading={isDeleting ? true : false}
            className={classNames('p-button-danger', { 'w-6rem pl-0 pr-0': !isDeleting })}
            tooltip="Delete"
            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            onClick={() => deletePromotionLicenseRequest()}
            disabled={!props.selectedPromotionLicenseList.length || isDeleting!}
          />
        </div>
      </div>

      <Dialog
        content={null}
        header={header}
        visible={requestLicenseModal}
        blockScroll={true}
        style={{ width: '50vw' }}
        onHide={() => onHide()}
        footer={footer}
      >
        <Divider />
        {selectedPromotionRequestList.map((el: any) => {
          return (
            <div key={el.ID}>
              <div className='flex justify-content-between'>
                <div>
                  <div><span className='font-bold'>Promotion Code : </span><span>{el.Promotion_Code}</span></div>
                  <div><span className='font-bold'>Quantity : </span><span>{el.Total_Quantity}</span></div>
                  <div><span className='font-bold'>Type : </span><span>{licenseTypeObject[el.License_Type]}</span></div>
                  <div><span className='font-bold'>User Name : </span><span>{el.User}</span></div>
                  <div><span className='font-bold'>User Mail : </span><span>{el.Mail}</span></div>
                  {openType === 'issue' &&
                    <div>
                      <span className='font-bold'>Expire Date : </span>
                      <Calendar id='expire' value={el.License_Expire_Date}
                        onChange={(e) => onDateChange(el, e.value)}
                        style={{ height: '20px', width: '140px' }}
                        // dateFormat="dd/mm/yy" mask="99/99/9999" 
                        dateFormat="yy/mm/dd" mask="9999/99/99"
                        touchUI
                        showIcon />
                    </div>}
                </div>
                <div className='flex align-items-center'>
                  <CustomButton
                    icon="pi pi-times"
                    className='p-button-danger'
                    tooltip="Exclude"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                    onClick={() => removeItemToSelected(el)}
                    disabled={!props.selectedPromotionLicenseList.length}
                  />
                </div>
              </div>
              <Divider />
            </div>
          )
        })}
      </Dialog>
    </>
  )
}
export default PromotionLicenseDecideModal