import { Toast } from 'primereact/toast'
import { RefObject } from 'react'

interface ToastProps {
  toast: RefObject<Toast> // Toast 참조를 추가합니다.
  sev: "success" | "info" | "warn" | "error"
  sum: string
  det: string
  time?: number
}

export const CustomToast = ({ toast, sev, sum, det, time = 5000 }: ToastProps) => {
  toast && toast.current?.show({ severity: sev, summary: sum, detail: det, life: time })
}
