import "./Navigation.css"
import { Link, useLocation } from "react-router-dom"

import { BsKeyFill } from "react-icons/bs"
import { Card } from "primereact/card"
import { Divider } from "primereact/divider"

export const Navigation = (props: any) => {
  const location = useLocation()
  const isAdmin = location.pathname.includes('/admin') ? 'admin' : 'user'

  const Licenselist = [{
    link: isAdmin === 'admin' ? '/admin/dashboard/license/list' : '/v1/dashboard/license/list',
    name: 'License List',
    icon: <BsKeyFill />
  },
  {
    link: isAdmin === 'admin' ? '/admin/dashboard/license/issue/request' : '/v1/dashboard/license/issue/request',
    name: 'License Request'
  },
  {
    link: isAdmin === 'admin' ? '/admin/dashboard/license/issue/history' : '/v1/dashboard/license/issue/history',
    name: 'License Issue History'
  },
    // {
    //   link: '/v1/dashboard/license/log',
    //   name: 'License Use Log'
    // }
  ]

  const PromotionList = [{
    link: '/admin/dashboard/promotion/list',
    name: 'Promotion List'
  }, {
    link: '/admin/dashboard/promotion/code',
    name: 'Promotion Code'
  }]

  const ManagerList = [{
    link: isAdmin === 'admin' ? '/admin/dashboard/manager/list' : '/v1/dashboard/manager/list',
    name: 'List'
  }]

  const navBody = (list: any) => {
    return list.map((el: any) => {
      return (
        <div className='nav-menu-item' key={el.name}>
          {/* {el.icon} */}
          <Link className='nav-menu-item-link' to={el.link}>{el.name}</Link>
        </div>
      )
    })
  }

  return (
    <Card className="navbarMain mr-4 h-screen">
      <nav>
        <div className='nav-menu-header'>
          <div className='nav-menu-header'>Home</div>
        </div>
        <Divider />
        <div className='nav-menu-header'>
          <Link className='nav-menu-header' to={isAdmin === 'admin' ? '/admin/dashboard/license' : '/v1/dashboard/license'}>License</Link>
        </div>
        {navBody(Licenselist)}
        <Divider />
        {isAdmin === 'admin' && <>
          <div className='nav-menu-header'>
            <Link className='nav-menu-header' to={'/admin/dashboard/promotion'}>Promotion</Link>
          </div>
          {navBody(PromotionList)}
          <Divider />
          <div className='nav-menu-header'>User</div>
        </>}
      </nav>
      {/* <Divider /> */}
      <div className='flex'>

      </div>
    </Card >
  )
}

export default Navigation