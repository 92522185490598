import React from 'react'
import '../App.css'

function SignUp() {
  return (
    <div className="App">
      SignUp Page.
    </div>
  )
}

export default SignUp
