import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

export const useDayJS = () => {
  const today = dayjs()
  const [periodList, setPeriodList] = useState<string[]>([])
  const [periodType, setPeriodType] = useState('lastWeek')

  useEffect(() => {
    switch (periodType) {
      case 'lastWeek':
        createPeriodList(getLastWeekPeriod())
        break
      case 'last7days':
        createPeriodList(getLast7DaysPeriod())
        break
      case 'thisWeek':
        createPeriodList(getThisWeekPeriod())
        break
      case 'lastMonth':
        createPeriodList(getLastMonthPeriod())
        break
      case 'last30days':
        createPeriodList(getLast30DaysPeriod())
        break
      case 'currentMonth':
        createPeriodList(getCurrentMonthPeriod())
        break
      default:
        console.error(`Invalid period type: ${periodType}`)
    }
  }, [periodType])

  function changeDayjs({ date }: { date: string }) {
    return dayjs(date)
  }

  function exChangeFormat({ date, format }: { date: any, format: string }) {
    return dayjs(date).format(format)
  }

  function getDate({ date, type }: { date: string, type: 'beforOneYear' | 'beforOneMonth' | 'beforOneWeek' | 'afterTwoWeek' | 'today' }) {
    let _date = dayjs(date)
    if (type === 'beforOneYear') { _date = _date.subtract(1, 'year') }
    if (type === 'beforOneMonth') { _date = _date.subtract(1, 'month') }
    if (type === 'beforOneWeek') { _date = _date.subtract(1, 'week') }
    if (type === 'afterTwoWeek') { _date = _date.add(14, 'day') }
    if (type === 'today') { }
    // _date = _date.format('YYYY-MM-DD HH:mm:ss')
    return _date
  }

  function getToday({ format = 'YYYY-MM-DD HH:mm:ss' }: { format?: string }) {
    return today.format(format)
  }

  function getAfterDay() {
    return today.add(1, 'day').format('YYYY-MM-DD')
  }

  function getLastWeekPeriod() {
    const start = today.subtract(1, 'week').startOf('week')
    const end = today.subtract(1, 'week').endOf('week')
    return { start, end }
  }

  function getLast7DaysPeriod() {
    const start = today.subtract(6, 'day')
    const end = today
    return { start, end }
  }

  function getThisWeekPeriod() {
    const start = today.startOf('week')
    const end = today.endOf('week')
    return { start, end }
  }

  function getLastMonthPeriod() {
    const start = today.subtract(1, 'month').startOf('month')
    const end = today.subtract(1, 'month').endOf('month')
    return { start, end }
  }

  function getLast30DaysPeriod() {
    const start = today.subtract(29, 'day')
    const end = today
    return { start, end }
  }

  function getCurrentMonthPeriod() {
    const start = today.startOf('month')
    const end = today.endOf('month')
    return { start, end }
  }

  function createPeriodList({ start, end }: { start: Dayjs, end: Dayjs }) {
    let _t: string[] = []
    for (let date = start; date.isBefore(end) || date.isSame(end); date = date.add(1, 'day')) {
      _t.push(date.format('YYYY-MM-DD'))
    }
    setPeriodList(_t)
  }

  function isToday({ date }: { date: string }) {
    const today = getToday({ format: 'YYYY-MM-DD' })
    const targetDate = exChangeFormat({ date, format: 'YYYY-MM-DD' })

    return today === targetDate;
  }

  function isNow({ date }: { date: string }) {
    const now = dayjs(getToday({ format: 'YYYY-MM-DD HH:mm:ss' }), 'YYYY-MM-DD HH:mm:ss');
    const targetDate = dayjs(exChangeFormat({ date, format: 'YYYY-MM-DD HH:mm:ss' }), 'YYYY-MM-DD HH:mm:ss');
    return targetDate.isAfter(now);
  }

  return {
    periodList,
    periodType,
    isToday,
    setPeriodType,
    getToday,
    getDate,
    getLastWeekPeriod,
    getLast7DaysPeriod,
    getAfterDay,
    getThisWeekPeriod,
    getLastMonthPeriod,
    getLast30DaysPeriod,
    getCurrentMonthPeriod,
    exChangeFormat,
    isNow,
    changeDayjs,
  }
}
