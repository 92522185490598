import dayjs from 'dayjs'
import { useRef, useState } from 'react'

import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { DataTable, DataTableFilterMeta } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'

import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { CustomButton } from '../../../../components/button/Button'
import { defaultSelectedLicenseIssueHistoryColumn, licenseIssueHistoryColumn_admin, licenseTypeList } from '../../../../constants/table'

function LicenseIssueHistoryTable(props: any) {

  const columns = licenseIssueHistoryColumn_admin

  const toast = useRef<Toast>(null)

  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [selectedColumns, setSelectedColumns] = useState<TableColums[]>(columns.filter((el: TableColums) => defaultSelectedLicenseIssueHistoryColumn.includes(el.field)))
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    License_Type: { value: null, matchMode: FilterMatchMode.IN },
    License_Limit: { value: null, matchMode: FilterMatchMode.IN },
    Issue_State: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  })

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value
    let _filters = { ...filters }

    // @ts-ignore
    _filters['global'].value = value;

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      License_Type: { value: null, matchMode: FilterMatchMode.IN },
      License_Limit: { value: null, matchMode: FilterMatchMode.IN },
      Issue_State: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    })
    setGlobalFilterValue('')
    setSelectedColumns(columns.filter((el: LicenseIssueRequestColums) => defaultSelectedLicenseIssueHistoryColumn.includes(el.field)))
  }

  //  header
  const renderHeader = () => {
    return (
      <div className="flex flex-wrap justify-content-between">
        <div style={{ textAlign: 'left', flex: '1 0 auto' }}>
        </div>
        <div className='flex flex-wrap flex-grow'>
          <div className='mr-3'>
            <CustomButton
              label="Filter Clear"
              styleOption="outlined"
              onClick={initFilter}
            />
          </div>
          <div className='flex flex-wrap'>
            <div className='mr-3' style={{ textAlign: 'left', flex: '1 1 auto' }}>
              <MultiSelect maxSelectedLabels={4} value={selectedColumns} options={columns} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
            </div>
            <div className='flex' style={{ flex: '1 1 auto', flexDirection: 'column', flexWrap: 'wrap' }}>
              <span className="p-input-icon-left" style={{ marginBottom: '1em', display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-search" style={{ marginRight: '0.5em' }} />
                <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder="Keyword Search" />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //  table header MultiSelect 관련
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some((sCol: any) => sCol.field === col.field))
    setSelectedColumns(orderedSelectedColumns)
  }

  //  license issue state
  const licenseIssueStateBody = (rowData: ILicenseIssueHistory) => {
    const textColor = rowData.Issue_State === 'true' ? 'text-blue-500' : 'text-red-500'
    const text = rowData.Issue_State === 'true' ? 'Allow' : 'Deny'
    return <div className='ml-2'><span className={`font-bold ${textColor}`}>{text}</span></div>
  }
  const issueStateFilterBody = (options: { type: string, value: string }) => {
    return <span className={classNames('font-bold', (options.type === 'Allow') ? 'text-blue-500' : 'text-red-500')}>{options.type}</span>
  }
  const statusList = [{ type: 'Allow', value: 'true' }, { type: 'Deny', value: 'false' }]
  const statusFilter = (options: any) => {
    return <Dropdown
      value={options.value}
      options={statusList}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={issueStateFilterBody}
      optionLabel="type"
      placeholder="Select a Status"
      className="p-column-filter"
      showClear />
  }


  //  License Type
  const licenseTypeBody = (rowData: ILicenseIssueHistory) => { return <div className='w-8rem'><span>{rowData.License_Type}</span></div> }
  const licenseTypeFilterBody = (options: any) => { return <span >{options}</span> }
  const licenseTypeFilter = (options: ColumnFilterElementTemplateOptions) => {
    return <MultiSelect
      value={options.value}
      options={licenseTypeList}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={licenseTypeFilterBody}
      placeholder="Select a Status" className="p-column-filter"
    />
  }

  //  License Limit
  const typeList = [{ type: 'limit', value: 1 }, { type: 'unlimit', value: 2 }]
  const licenseLimitBody = (rowData: ILicenseIssueHistory) => { return <div className='text-center'><span>{rowData.License_Limit === 1 ? 'limit' : 'unlimit'}</span></div> }
  const licenseLimitFilterBody = (options: { type: string, value: number }) => { return <span >{options.type}</span> }
  const licenseLimitFilter = (options: ColumnFilterElementTemplateOptions) => {
    return <MultiSelect
      value={options.value}
      options={typeList}
      optionLabel="type"
      onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
      itemTemplate={licenseLimitFilterBody}
      placeholder="Select a Limit type" className="p-column-filter"
    />
  }

  //  License Request Date
  const requestDateBody = (rowData: ILicenseIssueHistory) => {
    return <div className='flex justify-content-center'><span>{dayjs(rowData.License_Request_Date).format('YYYY-MM-DD')}</span></div>
  }

  //  License Issue Date
  const issueDateBody = (rowData: ILicenseIssueHistory) => {
    return <div className='flex justify-content-center'><span>{dayjs(rowData.License_Issued_Date).format('YYYY-MM-DD')}</span></div>
  }

  //  License Expire Date
  const expireDateBody = (rowData: ILicenseIssueHistory) => {
    let returnMsg = '-'
    if (rowData.License_Limit === 2) {
      returnMsg = dayjs(rowData.License_Expiration_Date).format('YYYY-MM-DD')
    }
    return <div className='flex justify-content-center'><span>{returnMsg}</span></div>
  }

  return (
    <div className="card">
      <Toast ref={toast} content={null} />
      <DataTable
        //  테이블 표기 데이터 | 테이블 헤더 | 테이블 스타일 추가
        value={props.licenseIssueHistory} header={renderHeader} tableStyle={{ minWidth: '50rem' }}
        //  글로벌 필터
        globalFilterFields={selectedColumns.map((el: TableColums) => el.field)}
        //  테이블 크기 | 테이블 줄무늬 추가 | 컬럼 정렬 추가
        size='small' stripedRows removableSort
        //  검색 필터 옵션 | 데이터 없을시 보여주는 메시지 | 컬럼 헤더 위치 이동 가능
        filters={filters} emptyMessage="No License found." reorderableColumns
        //  페이지 네이션 추가
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
        //  table 컬럼 선택기능 추가 ( 단일 선택 )
        selectionMode="single" selectionPageOnly selection={props.selectedIssueHistory} onSelectionChange={(e) => props.setselectedIssueHistory(e.value)}
        //  필터 데이터 변경시 동작
        onValueChange={filteredValues => setFilteredValue(filteredValues)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
        {selectedColumns.map((col) => {
          if (col.field === 'License_Type') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              body={licenseTypeBody} bodyClassName='w-2rem'
              filter filterElement={licenseTypeFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
              sortable
            />
          }
          if (col.field === 'License_Limit') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              body={licenseLimitBody} bodyClassName='w-1rem'
              filter filterElement={licenseLimitFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
              sortable
            />
          }
          if (col.field === 'Issue_State') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              body={licenseIssueStateBody} bodyClassName='w-1rem'
              filter filterElement={statusFilter} filterMenuStyle={{ width: '14rem' }}
              showFilterMatchModes={false} showFilterOperator={false} showFilterMenuOptions={false}
              sortable
            />
          }
          if (col.field === 'Total_Quantity') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              bodyClassName='w-1rem'
              sortable
            />
          }
          if (col.field === 'License_Request_Date') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              body={requestDateBody} bodyClassName='w-10rem'
              sortable
            />
          }
          if (col.field === 'License_Issued_Date') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              body={issueDateBody} bodyClassName='w-10rem'
              sortable
            />
          }
          if (col.field === 'License_Expiration_Date') {
            return <Column key={col.field} field={col.field} filterField={col.field} header={col.header}
              body={expireDateBody} bodyClassName='w-10rem'
              sortable
            />
          }
          return <Column key={col.field} field={col.field} filterField={col.field} header={col.header} sortable />
        })}
      </DataTable>
    </div>
  )
}

export default LicenseIssueHistoryTable