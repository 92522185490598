import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

export const ClipboardButton = ({ textToBeCopied }: { textToBeCopied: string }) => {
  const [isCopied, setIsCopied] = useState(false)

  const onCopyText = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <CopyToClipboard text={textToBeCopied} onCopy={onCopyText} >
      <button disabled={isCopied}>{isCopied ? "Copied!" : "Copy"}</button>
    </CopyToClipboard>
  )
}
