export const licenseTypeList_object = [
  { name: 'ZCM (Migration)', value: 1 },
  { name: 'ZDM (Backup)', value: 2 },
  { name: 'ZDM (DR)', value: 3 },
  { name: 'ZDM (Migration)', value: 4 },
  { name: 'ZEM (서버)', value: 5 },
  { name: 'ZEM (용량)', value: 6 },
]

export const licenseExpirSelect = [
  { name: '무기한', value: 1 },
  { name: '기한', value: 2 },
]

export const licenseCategory = [
  { name: 'test (사내 테스트용)', value: 'test' },
  { name: 'poc (poc)', value: 'poc' },
  { name: 'product (외부 발급)', value: 'product' },
]

export const promotionRequestState = ['approve', 'waiting', 'deny']