import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { licenseTypeObject } from '../constants/table'
import { URLLIST } from '../constants/url'
import { deleteAPI, getAPI, postAPI, putAPI } from '../services/api'
import { useDayJS } from './useDayJS'

export const usePromotion = () => {

  const { isToday, isNow } = useDayJS()
  const defaultLicenseCategorizationByLicenseType: { [key: string]: number } = {
    'ZCM (Migration)': 0,
    'ZDM (Backup)': 0,
    'ZDM (DR)': 0,
    'ZDM (Migration)': 0,
    'ZEM (서버)': 0,
    'ZEM (용량)': 0,
  }
  //  프로모션 대쉬보드 라이센스 기한
  const [promotionLicenseDashBoardDatePeriod, setPromotionLicenseDashBoardDatePeriod] = useState<any>(null)
  //  프로모션 라이센스 목록
  const [promotionLiceseList, setPromotionLiceseList] = useState([])
  //  프로모션 코드 목록
  const [promotionCodeList, setPromotionCodeList] = useState([])
  //  오늘 승인된 프로모션 라이센스 요청 
  const [promotionLicenseApprovedToday, setPromotionLicenseApprovedToday] = useState([])
  //  오늘 들어온 프로모션 라이센스 요청
  const [promotionLicenseRequestToday, setPromotionLicenseRequestToday] = useState([])
  //  승인 대기중 프로모션 라이센스
  const [promotionLicenseWaiting, setPromotionLicenseWaiting] = useState([])
  //  타입별 프로모션 라이센스 수량 (리스트)
  const [promotionLicenseCategorizationByLicenseTypeList, setPromotionLicenseCategorizationByLicenseTypeList] = useState([
    { name: 'ZCM', value: 0 },
    { name: 'ZDM', value: 0 },
    { name: 'ZEM', value: 0 },
  ])
  //  유형별 프로모션 라이센스 수량 (리스트)
  const [promotionLicenseCategorizationList, setPromotionLicenseCategorizationList] = useState([
    { name: 'ZCM (Migration)', value: 0 },
    { name: 'ZDM (Backup)', value: 0 },
    { name: 'ZDM (DR)', value: 0 },
    { name: 'ZDM (Migration)', value: 0 },
    { name: 'ZEM (서버)', value: 0 },
    { name: 'ZEM (용량)', value: 0 },
  ])
  //  유형별 프로모션 라이센스 수량 (객체)
  const [promotionLicenseCategorizationByLicenseType, setPromotionLicenseCategorizationByLicenseType] = useState<{ [key: string]: number }>({
    'ZCM (Migration)': 0,
    'ZDM (Backup)': 0,
    'ZDM (DR)': 0,
    'ZDM (Migration)': 0,
    'ZEM (서버)': 0,
    'ZEM (용량)': 0,
  })
  //  상태별 프로모션 코드 수량
  const [codeState, setCodeState] = useState([
    { name: 'available', value: 0 },
    { name: 'not available', value: 0 }
  ])
  //  타입별 프로모션 코드 수량 (리스트)
  //  유형별 프로모션 코드 수량 (객체)
  //  프로모션 코드별 발급 수량
  const [promotionCodePerUseage, setPromotionCodePerUseage] = useState<any>([])

  useEffect(() => {
    //  오늘 승인된 프로모션 라이센스 필터
    const getPromotionLicenseApprovedToday = () => {
      setPromotionLicenseApprovedToday(promotionLiceseList.filter((el: any) => isToday({ date: el.License_Create_Date }) && el.State === 'approve'))
    }

    //  현재 승인대기중인 프로모션 라이센스 필터
    const getPromotionLicenseWaiting = () => {
      setPromotionLicenseWaiting(promotionLiceseList.filter((el: any) => el.State === 'waiting'))
    }

    //  오늘 요청된 프로모션 라이센스 필터
    const getPromotionLicenseRequsetToday = () => {
      setPromotionLicenseRequestToday(promotionLiceseList.filter((el: any) => isToday({ date: el.License_Request_Date })))
    }

    getPromotionLicenseApprovedToday()
    getPromotionLicenseWaiting()
    getPromotionLicenseRequsetToday()
    categorizationByLicenseType({ list: promotionLiceseList })
    return () => { }
  }, [promotionLiceseList])

  useEffect(() => {
    //  활성 / 비활성
    const distinctionActiveInActive = () => {
      const _codeState: { [key: string]: number } = { 'available': 0, 'not available': 0 }
      promotionCodeList.map((el: any) => { _codeState[el.State] += 1 })

      const _t: any[] = []
      Object.keys(_codeState).map((key: any) => { _t.push({ value: _codeState[key], name: key }) })
      setCodeState(_t)
    }
    //  promotion code별 사용 현황
    const promotionCodeUseSummary = () => {
      const _codeList: { [key: string]: number } = {}
      // promotionCodeList.map((el: any) => { _codeList.push({ name: el.Promotion_Code, value: 0 }) })
      promotionLiceseList.map((el: any) => {
        console.log(el)
        if (el.Promotion_Code in _codeList) { _codeList[el.Promotion_Code] += 1 }
        else _codeList[el.Promotion_Code] = 1
      })
      setPromotionCodePerUseage(_codeList)
    }


    distinctionActiveInActive()
    promotionCodeUseSummary()
    return () => { }
  }, [promotionCodeList])

  //  라이센스 타입별 분리
  useEffect(() => {
    const categorizationLicense = () => {
      const _t: any[] = []
      Object.keys(promotionLicenseCategorizationByLicenseType).map((key) => {
        _t.push({ value: promotionLicenseCategorizationByLicenseType[key], name: key })
      })
      setPromotionLicenseCategorizationList(_t)
    }

    const categorizationLicenseByType = () => {
      const _t: { [key: string]: number } = { 'ZCM': 0, 'ZDM': 0, 'ZEM': 0 }
      Object.keys(promotionLicenseCategorizationByLicenseType).map((key) => {
        if (key.includes('ZCM')) { _t['ZCM'] += promotionLicenseCategorizationByLicenseType[key] }
        if (key.includes('ZDM')) { _t['ZDM'] += promotionLicenseCategorizationByLicenseType[key] }
        if (key.includes('ZEM')) { _t['ZEM'] += promotionLicenseCategorizationByLicenseType[key] }
      })

      const _t2: any[] = []
      Object.keys(_t).map((key) => { _t2.push({ value: _t[key], name: key }) })
      setPromotionLicenseCategorizationByLicenseTypeList(_t2)
    }

    categorizationLicense()
    categorizationLicenseByType()
  }, [promotionLicenseCategorizationByLicenseType])

  //  프로모션 라이센스 대쉬보드 날짜 선택시 필터
  useEffect(() => {
    if (promotionLicenseDashBoardDatePeriod) {
      const startDate = dayjs(promotionLicenseDashBoardDatePeriod[0])
      const endDate = dayjs(promotionLicenseDashBoardDatePeriod[1])

      //  라이센스 리스트 필터링
      const filterLicenseList = () => {
        const _promotionLiceseList = promotionLiceseList.filter((el: ILicense) => dayjs(el.License_Create_Date).isAfter(startDate) && dayjs(el.License_Create_Date).isBefore(endDate) || dayjs(el.License_Create_Date).isSame(startDate) || dayjs(el.License_Create_Date).isSame(endDate))
        categorizationByLicenseType({ list: _promotionLiceseList })
      }

      filterLicenseList()
    }
    return () => { }
  }, [promotionLicenseDashBoardDatePeriod])

  //  프로모션 라이센스 타입별 필터
  async function categorizationByLicenseType({ list }: { list: ILicense[] }) {
    const _t = { ...defaultLicenseCategorizationByLicenseType }
    list.map((el: ILicense) => { _t[licenseTypeObject[el.License_Type]] += 1 })
    setPromotionLicenseCategorizationByLicenseType(_t)
  }

  //  현재 프로모션 라이센스 요청 및 발급 목록
  async function getPromotionLicenseRequsetList() {
    try {
      const path = URLLIST['PROMOTION_LICENSE_LIST']
      await getAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { setPromotionLiceseList(result?.data?.list ?? []) }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  현재 프로모션 라이센스 및 발급 요청 삭제
  async function deletePromotionLicense({ data }: { data: any }) {
    try {
      const path = URLLIST['PROMOTION_LICENSE_DELETE'].replace('{ID}', data.ID)
      return await deleteAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  프로모션 라이센스 발급 요청
  async function requestPromotionLicense({ data }: { data: any }) {
    try {
      const path = URLLIST['PROMOTION_LICENSE_ISSUE_REQUEST']
      return await postAPI({ path, body: data })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  프로모션 라이센스 요청 승인 및 거절
  async function decidePromotionLicenseRequest({ data, state }: { data: any, state: 'approve' | 'deny' }) {
    try {
      const path = URLLIST['PROMOTION_LICENSE_REQUEST_DECIDE']
      return await postAPI({ path, body: { data, state } })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  현재 프로모션 코드 목록
  async function getPromotionCodeList() {
    try {
      const path = URLLIST['PROMOTION_CODE_LIST']
      await getAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { setPromotionCodeList(result?.data?.list ?? []) }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  프로모션 코드만 빼오기
  function getPromotionCodeOnlyList() {
    // let list: any[] = []
    // list = promotionCodeList.map((el:any)=>{
    //   return el.
    // })
    // return list
  }

  //  프로모션 코드 등록
  async function addPromotionCode({ data }: { data: IPromotionCode }) {
    try {
      const path = URLLIST['PROMOTION_CODE_ADD']
      return await postAPI({ path, body: data })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  프로모션 코드 삭제
  async function deletePromotionCode({ ID }: { ID: number }) {
    try {
      const path = URLLIST['PROMOTION_CODE_DELETE'].replace('{ID}', ID.toString())
      return await deleteAPI({ path })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  //  프로모션 코드 정보 수정
  async function editPromotionCode({ data }: { data: IPromotionCode }) {
    try {
      const path = URLLIST['PROMOTION_CODE_EDIT']
      return await putAPI({ path, body: data })
        .then((result: any) => {
          if (result.state === 'success') { return result }
          else throw new Error(result.message)
        })
        .catch((error) => { throw error })
    } catch (error) { throw error }
  }

  return {
    //  변수
    codeState,
    promotionLiceseList,
    promotionCodeList,
    promotionLicenseApprovedToday,
    promotionLicenseRequestToday,
    promotionLicenseWaiting,
    promotionLicenseCategorizationByLicenseTypeList,
    promotionLicenseCategorizationList,
    promotionLicenseDashBoardDatePeriod,
    promotionCodePerUseage,
    //  함수
    getPromotionCodeList,
    getPromotionLicenseRequsetList,
    setPromotionLiceseList,
    setPromotionCodeList,
    setPromotionLicenseDashBoardDatePeriod,
    requestPromotionLicense,
    decidePromotionLicenseRequest,
    addPromotionCode,
    deletePromotionCode,
    editPromotionCode,
    deletePromotionLicense,
    getPromotionCodeOnlyList,
  }
}