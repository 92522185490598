import { useEffect, useRef, useState } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'

import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { ProgressBar } from 'primereact/progressbar'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'

import { licenseCategory, licenseExpirSelect, licenseTypeList_object } from '../../constants/dropdown'
import { licenseTypeObject } from '../../constants/table'
import nationCode from '../../contexts/nationCode.json'
import { useAuth } from '../../hooks/useAuth'
import { useDayJS } from '../../hooks/useDayJS'
import { useLicense } from '../../hooks/useLicense'
import { CustomButton } from '../button/Button'
import { CustomToast } from '../toast/Toast'


export const RequestLicenseModal = (props: any) => {
  const toast = useRef<Toast>(null)
  const { loginCheck } = useAuth()
  const { getToday, getDate, exChangeFormat } = useDayJS()
  const { requestLicense } = useLicense()
  const nationCodeList = nationCode.map((item) => item)
  const afterTwoWeek = exChangeFormat({ date: getDate({ date: getToday({}), type: 'afterTwoWeek' }), format: 'ddd MMM DD YYYY HH:mm:ss [GMT]Z (ZZ)' })

  const defaultValues = {
    MANAGER_IP: '127.0.0.1',
    MANAGER_LOGIN_MAIL: props?.userType === 'admin' ? 'admin@zconverter.com' : '',
    UUID: '',
    LICENSE_TYPE: '',
    LISENSE_ISSUE_TYPE: 'new',
    CUSTOMER: props?.userType === 'admin' ? 'ZCONVERTER' : '',
    PROJECT_ID: props?.userType === 'admin' ? 'ZCONVERTER_TEST' : '',
    LICENSE_COUNT: 0,
    ZIA_USER_MAIL: '',
    ZIA_USER_NAME: '',
    REISSUANCE_UUID: '',
    COUNTRY: {
      "ISOAlpha2": "KO",
      "ISOAlpha3": "KOR",
      "ISONumeric": 410,
      "Continents": "Asia",
      "en": "Republic of Korea",
      "kr": "대한민국"
    },
    LICENSE_NAME: '',
    LICENSE_LIMIT: 2,
    LICENSE_VERSION: '',
    EXPIRATION_DATE: afterTwoWeek,
    CATEGORY: 'test',
    LICENSE_RECIVER_MAIL: ''
  }

  const [isWorking, setIsWorking] = useState(false)
  const [requestLicenseModal, setRequestLicenseModal] = useState(false)

  const { control, formState: { errors }, handleSubmit, reset, watch, setValue } = useForm({ defaultValues })

  let licenseType = licenseTypeObject[watch('LICENSE_TYPE').toString()] ?? ''
  const issueType = props?.userType === 'admin' ? 'Issue' : "Request"

  const openModal = async () => {
    reset(defaultValues)
    setRequestLicenseModal(true)
  }

  const onHide = () => { setRequestLicenseModal(false) }

  useEffect(() => { //  언어 설정에 맞춰 라이센스 발급 항목 수정
    licenseTypeList_object.map((el: { name: string, value: number }, idx: number) => {
      const name = `license_type${idx + 1}`
      el.name = props.t(name)
      return el
    })

    licenseExpirSelect.map((el: { name: string, value: number }, idx: number) => {
      const name = `license_expir_Select${idx + 1}`
      el.name = props.t(name)
      return el
    })

    licenseCategory.map((el: { name: string, value: string }, idx: number) => {
      const name = `license_category${idx + 1}`
      el.name = props.t(name)
      return el
    })

  }, [props.i18n?.languages])

  const getFormErrorMessage = (name: string) => {
    const errorMessages = errors as { [key: string]: FieldError }
    return errorMessages[name] ? <small className="p-error">{errorMessages[name].message}</small> : <small className="p-error"></small>
  }

  const onSubmit = async (data: any) => {
    try {
      // console.log(data)
      setIsWorking(true)
      if (watch('LICENSE_TYPE').toString() === '5' || watch('LICENSE_TYPE').toString() === '6') {
        data.MANAGER_LOGIN_MAIL = 'zconverter'
      }
      data.LICENSE_VERSION = data.LICENSE_TYPE === 1 ? 4 : (2 <= data.LICENSE_TYPE && data.LICENSE_TYPE <= 4) ? 7 : 6
      if (data.EXPIRATION_DATE) data.EXPIRATION_DATE = exChangeFormat({ date: data.EXPIRATION_DATE, format: 'YYYY-MM-DD HH:mm:ss' })
      if (data.LICENSE_NAME === '') data.LICENSE_NAME = `${data.PROJECT_ID}'s license`

      //  UUID 형식 체크
      data.UUID = data.UUID.trim()
      if (!(/^\{/.test(data.UUID))) data.UUID = '{' + data.UUID
      if (!(/\}$/.test(data.UUID))) data.UUID = data.UUID + '}'
      //  Country 형태 수정
      data.COUNTRY = data.COUNTRY.ISOAlpha2

      await loginCheck()
        .then((result) => {
          requestLicense({ data })
            .then((r) => {
              if (r?.state === 'success') {
                CustomToast({ toast, sev: 'success', sum: r.message, det: `Name : ${data.LICENSE_NAME}`, time: 5000 })
                if (props.userType === 'user') props.getLicenseIssueRequestList()
                if (props.userType === 'admin') props.getLicenseList()
                onHide()
              }
              else CustomToast({ toast, sev: 'error', sum: 'license request fail', det: `Name : ${data.LICENSE_NAME}`, time: 5000 })
            })
            .catch((e) => {
              CustomToast({ toast, sev: 'error', sum: 'license request fail', det: `Name : ${data.LICENSE_NAME}`, time: 5000 })
            })
        })
    } catch (error: any) {
      CustomToast({ toast, sev: 'error', sum: 'license request fail', det: error.message, time: 5000 })
    } finally {
      setIsWorking(false)
    }
  }

  const selectedCountryTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.en} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.ISOAlpha2.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.en}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.en} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.ISOAlpha2.toLowerCase()}`} style={{ width: '18px' }} />
        <div>{option.en}</div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} content={null} />
      <CustomButton
        // icon="pi pi-plus"
        label={issueType}
        className='p-button-success w-6rem pl-0 pr-0'
        tooltip={`License ${issueType}`}
        tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
        onClick={openModal}
      />
      <Dialog header={`License ${issueType}`} content={null}
        visible={requestLicenseModal}
        blockScroll={true}
        style={{ width: '50vw' }}
        onHide={() => onHide()}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <Divider align="left" type="dashed" >
            <div>Required</div>
          </Divider>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="CATEGORY" control={control}
                rules={{ required: 'License Category is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown id={field.name} value={field.value}
                    onChange={(e) => field.onChange(e.value)} options={licenseCategory} optionLabel="name"
                    className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="CATEGORY" className={classNames({ 'p-error': errors.CATEGORY })}>License Category*</label>
            </span>
            {getFormErrorMessage('CATEGORY')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="LICENSE_TYPE" control={control}
                rules={{ required: 'License Type is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown id={field.name} value={field.value}
                    onChange={(e) => field.onChange(e.value)} options={licenseTypeList_object} optionLabel="name"
                    className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="LICENSE_TYPE" className={classNames({ 'p-error': errors.LICENSE_TYPE })}>License Type*</label>
            </span>
            {getFormErrorMessage('LICENSE_TYPE')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="UUID" control={control}
                rules={{
                  required: `${licenseType} UUID is required.`,
                  pattern: {
                    value: /^[a-zA-Z0-9{}-]+$/,
                    message: `${licenseType} UUID can only contain English letters (uppercase and lowercase), numbers, curly braces {}, and hyphens -.`,
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="UUID" className={classNames({ 'p-error': !!errors.UUID })}>{licenseType} UUID*</label>
            </span>
            {getFormErrorMessage('UUID')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="MANAGER_LOGIN_MAIL" control={control}
                rules={!((watch('LICENSE_TYPE').toString() === '5' || watch('LICENSE_TYPE').toString() === '6')) ? { required: `${licenseType} licenses require a Manager Portal login ID.` } : { required: '' }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}
                    value={(watch('LICENSE_TYPE').toString() === '5' || watch('LICENSE_TYPE').toString() === '6') ? 'ZCONVERTER' : field.value}
                    disabled={watch('LICENSE_TYPE').toString() === '5' || watch('LICENSE_TYPE').toString() === '6'}
                  />
                )} />
              <label htmlFor="MANAGER_LOGIN_MAIL" className={classNames({ 'p-error': !!errors.MANAGER_LOGIN_MAIL })}>{licenseType} Portal Login Mail ( ID )*</label>
            </span>
            {getFormErrorMessage('MANAGER_LOGIN_MAIL')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="CUSTOMER" control={control}
                rules={{ required: 'Customer Name is required.' }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}
                    value={(watch('CATEGORY') === 'test') ? 'ZCONVERTER' : field.value}
                  />
                )} />
              <label htmlFor="CUSTOMER" className={classNames({ 'p-error': !!errors.CUSTOMER })}>Customer Name*</label>
            </span>
            {getFormErrorMessage('CUSTOMER')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="PROJECT_ID" control={control}
                rules={{ required: 'Project Name(or ID) is required.' }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}
                    value={(watch('CATEGORY') === 'test') ? 'ZCONVERTER' : field.value}
                  />
                )} />
              <label htmlFor="PROJECT_ID" className={classNames({ 'p-error': !!errors.PROJECT_ID })}>Project ID(or Name)*</label>
            </span>
            {getFormErrorMessage('PROJECT_ID')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="LICENSE_COUNT" control={control}
                rules={{
                  required: 'License Quantity is required.',
                  pattern: {
                    value: /^[0-9]*$/, // 숫자만 입력되도록 정규식 패턴 설정
                    message: 'Only numbers are allowed',
                  },
                  min: { value: 1, message: 'License Quantity should be greater than or equal to 1.' },
                  max: { value: 3000, message: 'License Quantity should be Less  than or equal to 3000.' }
                }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} value={field.value.toString()} />
                )} />
              <label htmlFor="LICENSE_COUNT" className={classNames({ 'p-error': !!errors.LICENSE_COUNT })}>License Quantity*</label>
            </span>
            {getFormErrorMessage('LICENSE_COUNT')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="LICENSE_LIMIT" control={control}
                rules={{ required: 'License Expiration Type is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown id={field.name} value={field.value}
                    onChange={(e) => field.onChange(e.value)} options={licenseExpirSelect} optionLabel="name"
                    className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="LICENSE_LIMIT" className={classNames({ 'p-error': errors.LICENSE_LIMIT })}>License Expiration Type*</label>
            </span>
            {getFormErrorMessage('LICENSE_LIMIT')}
          </div>

          {(watch('LICENSE_LIMIT') === 2) &&
            <div className="field mt-5">
              <span className="p-float-label">
                <Controller name="EXPIRATION_DATE" control={control}
                  rules={{ required: 'License Expiration Date is required.' }}
                  render={({ field, fieldState }) => (
                    <Calendar id={field.name} value={new Date(field.value)} className={classNames({ 'p-invalid': fieldState.invalid })}
                      onChange={(e) => field.onChange(e.value)} dateFormat="yy/mm/dd" mask="99/99/9999" showIcon />
                  )} />
                <label htmlFor="EXPIRATION_DATE" className={classNames({ 'p-error': !!errors.EXPIRATION_DATE })}>Expiration date ( default : 2Weeks )</label>
              </span>
              {getFormErrorMessage('EXPIRATION_DATE')}
            </div>}

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="COUNTRY" control={control}
                rules={{ required: 'COUNTRY is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown id={field.name} value={field.value}
                    onChange={(e) => field.onChange(e.value)} options={nationCodeList} optionLabel="en"
                    className={classNames({ 'p-invalid': fieldState.invalid })}
                    filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                  />
                )} />
              <label htmlFor="COUNTRY" className={classNames({ 'p-error': errors.COUNTRY })}>Country*</label>
            </span>
            {getFormErrorMessage('COUNTRY')}
          </div>

          <Divider align="left" type="dashed" >
            <div>Optional</div>
          </Divider>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="LICENSE_RECIVER_MAIL" control={control}
                rules={{
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid email address. ( ex: www.exapmle.com )'
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}
                    value={field.value}
                  />
                )} />
              <label htmlFor="LICENSE_RECIVER_MAIL" className={classNames({ 'p-error': !!errors.LICENSE_RECIVER_MAIL })}>License Reciver Mail</label>
            </span>
            {getFormErrorMessage('LICENSE_RECIVER_MAIL')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="MANAGER_IP" control={control}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="MANAGER_IP" className={classNames({ 'p-error': errors.MANAGER_IP })}>{licenseType} IP</label>
            </span>
            {getFormErrorMessage('MANAGER_IP')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="LICENSE_NAME" control={control}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="LICENSE_NAME" className={classNames({ 'p-error': errors.LICENSE_NAME })}>License Name</label>
            </span>
            {getFormErrorMessage('LICENSE_NAME')}
          </div>

          <div className="field mt-5">
            <span className="p-float-label">
              <Controller name="REISSUANCE_UUID" control={control}
                render={({ field, fieldState }) => (
                  <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
              <label htmlFor="REISSUANCE_UUID" className={classNames({ 'p-error': !!errors.REISSUANCE_UUID })}>Before {licenseType} UUID</label>
            </span>
            {getFormErrorMessage('REISSUANCE_UUID')}
          </div>

          {/* <div className="field-checkbox">
                <Controller name="accept" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                  <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />
                )} />
                <label htmlFor="accept" className={classNames({ 'p-error': errors.accept })}>I agree to the terms and conditions*</label>
              </div> */}
          <Divider type="dashed" />
          <Button type="submit" label="Submit" className="mt-2" disabled={isWorking} />
          {isWorking && <ProgressBar mode="indeterminate" style={{ height: '6px' }} />}
        </form>
      </Dialog>
    </>
  )
}
