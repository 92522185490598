import React from 'react'
import '../App.css'

function NotFound() {
  return (
    <div className="App">
      NotFound Page.
    </div>
  )
}

export default NotFound
